// taqicalc - All Calcuations for TAQI (Think Air Quality Index)

// Calculate PM2.5 Index
const calcPM25Index = (pm25Concentration) => {
    let pm25Index = 0;
    switch (true) {
        case (pm25Concentration<12): pm25Index = 1; break;
        case (pm25Concentration>= 12 && pm25Concentration< 24): pm25Index = 2; break;
        case (pm25Concentration>= 24 && pm25Concentration< 36): pm25Index = 3; break;
        case (pm25Concentration>= 36 && pm25Concentration< 42): pm25Index = 4; break;
        case (pm25Concentration>= 42 && pm25Concentration< 48): pm25Index = 5; break;
        case (pm25Concentration>= 48 && pm25Concentration< 54): pm25Index = 6; break;
        case (pm25Concentration>= 54 && pm25Concentration< 59): pm25Index = 7; break;
        case (pm25Concentration>= 59 && pm25Concentration< 65): pm25Index = 8; break;
        case (pm25Concentration>= 65 && pm25Concentration< 71): pm25Index = 9; break;
        case (pm25Concentration>= 71): pm25Index = 10; break;
    }
    return pm25Index
}

// Calculate PM10 Index
const calcPM10Index = (pm10Concentration) => {
    let pm10Index = 0;
    switch (true) {
        case (pm10Concentration<17): pm10Index = 1; break;
        case (pm10Concentration>= 17 && pm10Concentration< 34): pm10Index = 2; break;
        case (pm10Concentration>= 34 && pm10Concentration< 51): pm10Index = 3; break;
        case (pm10Concentration>= 51 && pm10Concentration< 59): pm10Index = 4; break;
        case (pm10Concentration>= 59 && pm10Concentration< 67): pm10Index = 5; break;
        case (pm10Concentration>= 67 && pm10Concentration< 76): pm10Index = 6; break;
        case (pm10Concentration>= 76 && pm10Concentration< 84): pm10Index = 7; break;
        case (pm10Concentration>= 84 && pm10Concentration< 92): pm10Index = 8; break;
        case (pm10Concentration>= 92 && pm10Concentration< 101): pm10Index = 9; break;
        case (pm10Concentration>= 101): pm10Index = 10; break;
    }
    return pm10Index
}

// Calculate NO2 Index
const calcNO2Index = (no2Concentration) => {
    let no2Index = 0;
    switch (true) {
        case (no2Concentration<67): no2Index = 1; break;
        case (no2Concentration>= 68 && no2Concentration< 135): no2Index = 2; break;
        case (no2Concentration>= 135 && no2Concentration< 201): no2Index = 3; break;
        case (no2Concentration>= 201 && no2Concentration< 268): no2Index = 4; break;
        case (no2Concentration>= 268 && no2Concentration< 335): no2Index = 5; break;
        case (no2Concentration>= 335 && no2Concentration< 401): no2Index = 6; break;
        case (no2Concentration>= 401 && no2Concentration< 468): no2Index = 7; break;
        case (no2Concentration>= 468 && no2Concentration< 535): no2Index = 8; break;
        case (no2Concentration>= 535 && no2Concentration< 601): no2Index = 9; break;
        case (no2Concentration>= 601): no2Index = 10; break;
    }
    return no2Index
}

// Calculate O3 Index
const calcO3Index = (O3Concentration) => {
    let O3Index = 0;
    switch (true) {
        case (O3Concentration<33): O3Index = 1; break;
        case (O3Concentration>= 34 && O3Concentration< 67): O3Index = 2; break;
        case (O3Concentration>= 67 && O3Concentration< 101): O3Index = 3; break;
        case (O3Concentration>= 101 && O3Concentration< 121): O3Index = 4; break;
        case (O3Concentration>= 121 && O3Concentration< 141): O3Index = 5; break;
        case (O3Concentration>= 141 && O3Concentration< 161): O3Index = 6; break;
        case (O3Concentration>= 161 && O3Concentration< 188): O3Index = 7; break;
        case (O3Concentration>= 188 && O3Concentration< 214): O3Index = 8; break;
        case (O3Concentration>= 214 && O3Concentration< 241): O3Index = 9; break;
        case (O3Concentration>= 241): O3Index = 10; break;
    }
    return O3Index
}


const mixin = {
	data: () => ({
        thresholds: {
            pm25: [
                { index:1, min: 0, max: 11, colour: '#66BB6A', class:'low', text: 'Low' },
                { index:2, min: 11, max: 23, colour: '#66BB6A', class:'low', text: 'Low' },
                { index:3, min: 23, max: 35, colour: '#66BB6A', class:'low', text: 'Low' },
                { index:4, min: 35, max: 41, colour: '#FFA726', class:'moderate', text: 'Moderate' },
                { index:5, min: 41, max: 47, colour: '#FFA726', class:'moderate', text: 'Moderate' },
                { index:6, min: 47, max: 53, colour: '#FFA726', class:'moderate', text: 'Moderate' },
                { index:7, min: 53, max: 58, colour: '#EF5350', class:'high', text: 'High' },
                { index:8, min: 58, max: 64, colour: '#EF5350', class:'high', text: 'High' },
                { index:9, min: 64, max: 70, colour: '#EF5350', class:'high', text: 'High' },
                { index:10, min: 70, max: 99999, colour: '#AB47BC', class:'veryhigh', text: 'Very High' },
            ],
            pm10: [
                { index:1, min: 0, max: 16, colour: '#66BB6A', class:'low', text: 'Low' },
                { index:2, min: 16, max: 33, colour: '#66BB6A', class:'low', text: 'Low' },
                { index:3, min: 33, max: 50, colour: '#66BB6A', class:'low', text: 'Low' },
                { index:4, min: 50, max: 58, colour: '#FFA726', class:'moderate', text: 'Moderate' },
                { index:5, min: 58, max: 66, colour: '#FFA726', class:'moderate', text: 'Moderate' },
                { index:6, min: 66, max: 75, colour: '#FFA726', class:'moderate', text: 'Moderate' },
                { index:7, min: 75, max: 83, colour: '#EF5350', class:'high', text: 'High' },
                { index:8, min: 83, max: 91, colour: '#EF5350', class:'high', text: 'High' },
                { index:9, min: 91, max: 100, colour: '#EF5350', class:'high', text: 'High' },
                { index:10, min: 100, max: 99999, colour: '#AB47BC', class:'veryhigh', text: 'Very High' },
            ],
            no2: [
                { index:1, min: 0, max: 67, colour: '#66BB6A', class:'low', text: 'Low' },
                { index:2, min: 67, max: 134, colour: '#66BB6A', class:'low', text: 'Low' },
                { index:3, min: 135, max: 200, colour: '#66BB6A', class:'low', text: 'Low' },
                { index:4, min: 200, max: 267, colour: '#FFA726', class:'moderate', text: 'Moderate' },
                { index:5, min: 267, max: 334, colour: '#FFA726', class:'moderate', text: 'Moderate' },
                { index:6, min: 334, max: 400, colour: '#FFA726', class:'moderate', text: 'Moderate' },
                { index:7, min: 400, max: 467, colour: '#EF5350', class:'high', text: 'High' },
                { index:8, min: 467, max: 534, colour: '#EF5350', class:'high', text: 'High' },
                { index:9, min: 534, max: 600, colour: '#EF5350', class:'high', text: 'High' },
                { index:10, min: 600, max: 99999, colour: '#AB47BC', class:'veryhigh', text: 'Very High' },
            ],            
            o3: [
                { index:1, min: 0, max: 33, colour: '#66BB6A', class:'low', text: 'Low' },
                { index:2, min: 33, max: 66, colour: '#66BB6A', class:'low', text: 'Low' },
                { index:3, min: 66, max: 100, colour: '#66BB6A', class:'low', text: 'Low' },
                { index:4, min: 100, max: 120, colour: '#FFA726', class:'moderate', text: 'Moderate' },
                { index:5, min: 120, max: 140, colour: '#FFA726', class:'moderate', text: 'Moderate' },
                { index:6, min: 140, max: 160, colour: '#FFA726', class:'moderate', text: 'Moderate' },
                { index:7, min: 160, max: 187, colour: '#EF5350', class:'high', text: 'High' },
                { index:8, min: 187, max: 213, colour: '#EF5350', class:'high', text: 'High' },
                { index:9, min: 213, max: 240, colour: '#EF5350', class:'high', text: 'High' },
                { index:10, min: 240, max: 99999, colour: '#AB47BC', class:'veryhigh', text: 'Very High' },
            ]                          
        }
	}),
	computed: {
	},
	methods: {
        // PM 2.5
        CALC_PM25Data(forecastPM25Data) {
            // Method to return pm25Concentration and pm25Index
            /// Can be used for any PM2.5 Data e.g cop, TA Network Data, AURN, PCM
            // Pass in last 24 hours of data
            // Calculate the Average Concentration
            // Calculate the Index
            let total = 0;
            for (var i = 0; i < forecastPM25Data.length; i++) {
                total += forecastPM25Data[i]; 
            }
            let pm25Concentration = total / forecastPM25Data.length;                        
            let pm25Index = calcPM25Index(pm25Concentration);
            return {
                concentration: pm25Concentration,
                index: pm25Index
            }            
        },
        CALC_PM25ConsensusConcentration(copPM25Concentration, taPM25Concentration, aurnPM25Concentration, pcmPM25Concentration) {
            // PM2.5 concentration that gets presented to the dashboard for current air quality and is the greatest concentration value out of each reported for the cop, TA network, AURN and PCM indexes
            return Math.max(copPM25Concentration, taPM25Concentration, aurnPM25Concentration, pcmPM25Concentration)
        },
        CALC_PM25ConsensusIndex(copPM25Index, taPM25Index, aurnPM25Index, pcmPM25Index) {
            // PM2.5 air quality index that gets presented to the dashboard for current air quality and is the greatest index value out of each reported for the cop, TA network, AURN and PCM indexes.
            return Math.max(copPM25Index, taPM25Index, aurnPM25Index, pcmPM25Index)
        },
        // PM 10
        CALC_PM10Data(forecastPM10Data) {
            // Method to return pm10Concentration and pm10Index
            /// Can be used for any PM10 Data e.g cop, TA Network Data, AURN, PCM
            // Pass in last 24 hours of data
            // Calculate the Average Concentration
            // Calculate the Index
            let total = 0;
            for (var i = 0; i < forecastPM10Data.length; i++) {
                total += forecastPM10Data[i]; 
            }
            let pm10Concentration = total / forecastPM10Data.length;                        
            let pm10Index = calcPM10Index(pm10Concentration);
            return {
                concentration: pm10Concentration,
                index: pm10Index
            }            
        },
        CALC_PM10ConsensusConcentration(copPM10Concentration, taPM10Concentration, aurnPM10Concentration, pcmPM10Concentration) {
            // PM2.5 concentration that gets presented to the dashboard for current air quality and is the greatest concentration value out of each reported for the cop, TA network, AURN and PCM indexes
            return Math.max(copPM10Concentration, taPM10Concentration, aurnPM10Concentration, pcmPM10Concentration)
        },
        CALC_PM10ConsensusIndex(copPM10Index, taPM10Index, aurnPM10Index, pcmPM10Index) {
            // PM2.5 air quality index that gets presented to the dashboard for current air quality and is the greatest index value out of each reported for the cop, TA network, AURN and PCM indexes.
            return Math.max(copPM10Index, taPM10Index, aurnPM10Index, pcmPM10Index)
        },        
        // NO2 Concentration
        CALC_NO2Concentration(no2Concentration) {
            // NO2 Index - Current Hour Measure Concentration
            return calcNO2Index(no2Concentration)
        },
        CALC_NO2ConsensusConcentration(copNO2Concentration, pcmNO2Concentration) {
            // Method to return no2Concentration
            // Used  with cop PCM NO2 Data
            // Pass in cop, PCM oncentration
            // Calculate the Index
            return Math.max(copNO2Concentration, pcmNO2Concentration)
        },
        CALC_NO2ConsensusIndex(copNO2Index, pcmNO2Concentration) {
            // NO2air quality index that gets presented to the dashboard for current air quality and is the greatest index value out of each reported for the cop and PCM indexes.
            return Math.max(copNO2Index, pcmNO2Concentration)
        },
        // O3 Concentration
        CALC_O3Data(forecastO3Data) {
            // Method to return pm10Concentration and pm10Index
            /// Can be used for any PM10 Data e.g cop, TA Network Data, AURN, PCM
            // Pass in last 8 hour running mean 
            // Calculate the Average Concentration
            // Calculate the Index
            let total = 0;
            for (var i = 0; i < forecastO3Data.length; i++) {
                total += forecastO3Data[i]; 
            }
            let O3Concentration = total / forecastO3Data.length;                        
            let O3Index = calcO3Index(O3Concentration);
            return {
                concentration: O3Concentration,
                index: O3Index
            }            
        },
         CALC_O3ConsensusConcentration(copO3Concentration, pcmO3Concentration) {
            // Method to return O3Concentration
            // Used  with cop PCM O3 Data
            // Pass in cop, PCM oncentration
            // Calculate the Index
            return Math.max(copO3Concentration, pcmO3Concentration)
        },
        CALC_O3ConsensusIndex(copO3Index, pcmO3Concentration) {
            // O3air quality index that gets presented to the dashboard for current air quality and is the greatest index value out of each reported for the cop and PCM indexes.
            return Math.max(copO3Index, pcmO3Concentration)
        },
        // TAQI Data - Index / Status / Colour
        CALC_TAQIData(pm25ConsensusIndex, pm10ConsensusIndex, no2Index, o3Index) {
            let TAQIIndex = Math.max(pm25ConsensusIndex, pm10ConsensusIndex, no2Index, o3Index);
            let TAQIStatus = '';
            let TAQIColor = '';
            switch (true) {
                case (TAQIIndex < 4):
                    TAQIStatus = 'Low';
                    TAQIColor = '#66BB6A'; // Colour Green to Match UI Pallete
                    break;
                case (TAQIIndex > 3 && TAQIIndex < 7): 
                    TAQIStatus = 'Moderate';
                    TAQIColor = '#FFA726'; // Colour Orange to Match UI Pallete
                    break;
                case (TAQIIndex > 6 && TAQIIndex < 10):
                    TAQIStatus = 'High';
                    TAQIColor = '#EF5350'; // Colour Red to Match UI Pallete
                    break;
                case (TAQIIndex > 10):
                    TAQIStatus = 'Very Hight';
                    TAQIColor = '#AB47BC'; // Colour Purple to Match UI Pallete
                    break;
            }
            return {
                index: TAQIIndex,
                status: TAQIStatus,
                colour: TAQIColor
            }
        },
        // Pollen Count Index
        CALC_PollenCountData(forecastAPG, forecastBPG, forecastGPG, forecastMPG) {
            let pollenCountIndex = Math.max(forecastAPG, forecastBPG, forecastGPG, forecastMPG);
            let pollenStatus = '';
            let pollenColour = '';
            switch(true) {
                case (pollenCountIndex < 15):
                    pollenStatus = 'Low';
                    pollenColour = '#66BB6A'; // Colour Green to Match UI Pallete
                    break;
                case (pollenCountIndex >= 15 && pollenCountIndex < 90): 
                    pollenStatus = 'Moderate';
                    pollenColour = '#FFA726'; // Colour Orange to Match UI Pallete                    
                    break;
                case (pollenCountIndex >= 90 && pollenCountIndex < 1500):
                    pollenStatus = 'High';
                    pollenColour = '#EF5350'; // Colour Red to Match UI Pallete                    
                    break;
                case (pollenCountIndex > 1500):
                    pollenStatus = 'Very High';
                    pollenColour = '#AB47BC'; // Colour Purple to Match UI Pallete
                break;
            }
            return {
                count: pollenCountIndex,
                status: pollenStatus,
                colour: pollenColour
            }
        }
        // Health Impact Calculation
        // TODO - RG - Add Health Impact Calculation
    },
};


export default {
	install(Vue) {
		Vue.mixin(mixin);
	},
};
