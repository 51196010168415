<template>
    <div>
        <v-tabs v-model="selectedDay" fixed-tabs class="px-4" @change="findSelectedDayData()">
            <v-tab :href="'#today'">{{ forecastDates.today.day }}</v-tab>
            <v-tab :href="'#tomorrow'">{{ forecastDates.tomorrow.day }}</v-tab>
            <v-tab :href="'#dayAfterTomorrow'">{{ forecastDates.dayAfterTomorrow.day }}</v-tab>
        </v-tabs>
        <v-simple-table class="px-4" @touchstart.native.stop
    @touchmove.native.stop
    @touchend.native.stop>
            <template v-slot:default>
                <tbody>
                    <!--Hour-->
                    <tr >
                        <td v-for="hour in timeSeries" :key="hour">
                            <div  class="forecastblock" :class="isCurrentTime(hour)">{{hour}}</div>
                        </td>
                    </tr>
                    <!-- Index -->
                    <tr >
                        <td v-for="hour in timeSeries" :key="hour"> 
                            <div  class="forecastblock"  :class="getForecastColourPerHour(hour)">{{ getForecastDataPerHour(hour) }}</div>
                        </td>
                    </tr>
                    <!--Weather-->
                    <tr >
                        <td v-for="hour in timeSeries" :key="hour"> 
                            <div  class="forecastblock"> 
                                <v-icon size="32" class="grey--text">{{ getWeather(hour)}}</v-icon>
                            </div>
                        </td>
                    </tr>
                    <!--Temperature-->
                    <tr >
                        <td v-for="hour in timeSeries" :key="hour" align="center">
                            <div class="forecastblock"> {{getTemperature(hour)}} </div>
                        </td>
                    </tr>

                    <!--Wind Direction-->
                    <tr>
                        <td align="center" v-for="hour in timeSeries" :key="hour">
                            <div class="forecastblock">
                                <v-icon size="32" class="grey--text">{{ getWindDirection(hour)}}</v-icon>
                            </div>
                        </td>
                    </tr>
                    <!--Wind Speed-->
                    <tr>
                        <td v-for="hour in timeSeries" :key="hour" align="center">
                            <div class="forecastblock d-flex"> 
                                <div> 
                                {{getWindSpeed(hour)}} </div>
                            <div style="font-size: 10px"> mph</div>
                            </div>
                        </td>
                    </tr>
                   
                    
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>

<script>
export default {
    name: "ForecastMetTable",
    props: {
        forecastdata: {
            type: Object,
            default: () => {
                return {};
            },
        },
          forecastmetdata: {
            type: Array,
           
        },
        forecastDates: {
            type: Object,
            default: () => {
                return {};
            },
        },
        datatype: {
            type: String,
            default: '',
        },
        thresholdtype: {
            type: String,
            default: '',
        },
    },
    data: () => ({
        selectedDay: "today",
        selectedDayData : [],
        timeSeries: [],
    }),
    computed:{

      
    },
    methods: {

        // For each forecast date return the appropriate data
        // Set up the correct timelines
        findSelectedDayData() {

            const currentDay = this.$moment().format("ddd, DD MMM YYYY ")

            if ( this.selectedDay === "today"){
                this.selectedDayData = this.findSelectedDaysWeather( currentDay )
                this.timeSeries = [ "06:00", "09:00", "12:00", "15:00", "18:00", "21:00" ]
            }
            else if (this.selectedDay === "tomorrow"){

                const currentDayPlus1 = this.$moment().add(1, "day").format("ddd, DD MMM YYYY ");
                this.selectedDayData = this.findSelectedDaysWeather( currentDayPlus1 )
                this.timeSeries = ["00:00", "03:00" , "06:00", "09:00", "12:00", "15:00", "18:00", "21:00" ]

            }else if (this.selectedDay === "dayAfterTomorrow"){

                const currentDayPlus2 = this.$moment().add(2, "day").format("ddd, DD MMM YYYY ");
                this.selectedDayData = this.findSelectedDaysWeather( currentDayPlus2 )
                this.timeSeries = ["00:00", "03:00" , "06:00", "09:00", "12:00", "15:00", "18:00", "21:00" ]

            }

        },
        // This methos take the selected date as a parameter
        // Loops throught the METData array finds the coresponding data
        // Push them to an array and returns them
        findSelectedDaysWeather( day ){
            const selectedDayDataArray = [];
            for ( let i=0; i<this.forecastmetdata.length; i++){
                if( this.forecastmetdata[i].timestamp[0].includes( day)){
                    selectedDayDataArray.push( this.forecastmetdata[i])
                }
            }
            return selectedDayDataArray;
        },

        // Returns the correct weather icon for each time
        getWeather(hour ){
            var index=this.selectedDayData.findIndex(x=>x.timestamp[0].includes(hour))
            if ( index != -1){
                const weather = this.selectedDayData[index]["Weather Type"][2]
                switch(weather) {
                    case "H":
                        return "icons8-summer"
                    case "W":
                        return "icons8-windy-weather"
                    case "C":
                        return "icons8-winter"
                    case "R":
                        return "icons8-winter"
                    case "F":
                        return "icons8-fog"
                    case "S":
                        return "icons8-snow"
                    default:
                    }
            }else{
                return "icons8-winter"
            }
        },

        // Returns the correct weather icon for each time
        getWindDirection(hour ){
            var index=this.selectedDayData.findIndex(x=>x.timestamp[0].includes(hour))
            if ( index != -1){
                const windDirection = this.selectedDayData[index]["Wind Direction"][0]
                switch(windDirection) {
                    case "E":
                        return "icons8-east"
                    case "ENE":
                        return "icons8-east-north-east"
                    case "ESE":
                        return "icons8-east-south-east"
                    case "N":
                        return "icons8-north"
                    case "NE":
                        return "icons8-north-east"
                    case "NNE":
                        return "icons8-north-north-east"
                    case "NNW":
                        return "icons8-north-north-west"
                    case "NW":
                        return "icons8-north-west"
                    case "S":
                        return "icons8-south"
                    case "SE":
                        return "icons8-south-east"
                    case "SSE":
                        return "icons8-south-south-east"
                    case "SSW":
                        return "icons8-south-south-west"
                    case "SW":
                        return "icons8-south-west"
                    case "W":
                        return "icons8-west"
                    case "WNW":
                        return "icons8-west-north-west"
                    case "WSW":
                        return "icons8-west-south-west"
                    default:
                    }
            }else{
                return "icons8-west-south-west"
            }
        },
        
        //Returns the temperature for a specific hour
        getTemperature(hour){
            var index=this.selectedDayData.findIndex(x=>x.timestamp[0].includes(hour))
            if (index != -1){
                return this.selectedDayData[index]["Temperature"][0] + "°"
            }
        },

        // Returns the wind speed in mph
         getWindSpeed(hour){
            var index=this.selectedDayData.findIndex(x=>x.timestamp[0].includes(hour))
            if (index != -1){
                return this.selectedDayData[index]["Wind Speed"][0]
            }
        },

        isCurrentTime(value) {
            // get nearest hour
            const now = new Date();
            const currentHour = now.getHours();
            const currentDay = this.$moment().format("ddd");
            if (value === currentHour && this.forecastDates[this.selectedDay].day === currentDay) {
                return "now";
            } else {
                return "";
            }
        },
        getForecastColourPerHour(value) {
            let time = this.$moment(this.forecastDates[this.selectedDay].startTimeUnix, "X").add(value, "hour").format("X");
            let result = parseInt(this.forecastdata[time]);
            if (isNaN(result)) { result = 0;}
            
            let threshold = this.thresholds[this.datatype].find((x) => {
                if (this.thresholdtype === 'minmax') {               
                    if (result >= x.min && result < x.max) {
                        return x;
                    }
                } else if (this.thresholdtype === 'index') {
                    if (result === x.index) {
                        return x;
                    }
                }
            });
            return threshold.class;
        },
        getForecastDataPerHour(value) {
            let time = this.$moment(this.forecastDates[this.selectedDay].startTimeUnix, "X").add(value, "hour").format("X");
            return parseInt(this.forecastdata[time]);
        },

        getObjKey(obj, okey) {
            return Object.keys(obj).find(key => obj[key] === okey);
        }
    },
    created() {
       this.findSelectedDayData()
        
    }
};
</script>

<style scoped>
td {
    padding: 0px !important;
    /* border: 1px solid white; */
}
.forecastblock {
    width: 100%;
    height: 100%;
    padding: 10px;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.now {
    background-color: #a37fef !important;
    color: white !important;
}
</style>
