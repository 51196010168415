<template>
    <div>
        <v-chart class="chart" :option="option"/>
    </div>
</template>
 
<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { GaugeChart } from "echarts/charts";
import { TitleComponent, TooltipComponent, LegendComponent } from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { ref, defineComponent } from "vue";

use([CanvasRenderer, GaugeChart, TitleComponent, TooltipComponent, LegendComponent]);

export default defineComponent({
    name: "HelloWorld",
    components: {
        VChart,
    },
    props: {
        seriesdata: {
            type: Array,
            default: () => [],
        },
    },
    provide: {
        [THEME_KEY]: "light",
    },
    setup(vm) {
        const option = ref({
            series: [
                {
                    type: "gauge",
                    radius: "100%",
                    startAngle: 180,
                    endAngle: 0,
                    min: 0,
                    max: 10,
                    axisLine: {
                        lineStyle: {
                            width: 30,
                            color: [
                                [0.3, "#66BB6A"],
                                [0.6, "#FFA726"],
                                [0.9, "#EF5350"],
                                [1, "#AB47BC"],
                            ],
                        },
                    },
                    pointer: {
                        itemStyle: {
                            color: "inherit",
                        },
                    },
                    axisTick: {
                        distance: -30,
                        length: 8,
                        lineStyle: {
                            color: "#fff",
                            width: 0,
                        },
                    },
                    splitLine: {
                        distance: -30,
                        length: 30,
                        lineStyle: {
                            color: "#fff",
                            width: 4,
                        },
                    },
                    axisLabel: {
                        color: "inherit",
                        distance: 40,
                        fontSize: 20,
                    },
                    detail: {
                        valueAnimation: true,
                        formatter: "{value}",
                        color: "inherit",
                        offsetCenter: [0, "30%"],
                    },
                    data: vm.seriesdata
                },
            ],
        });

        return { option };
    },
});
</script>

<style scoped>
.chart {
    width: 100% !important;
    height: 350px !important;
    margin-bottom: -110px;
    /* height: 100vh; */
}
</style>
