<template>
    <div id="app" class="">
        <v-app>
            <AppUpdate />
            <!-- <CheckNetwork /> -->
            <app-header :user="user" :location="currentPostcodeLocation" v-on:changelocation="changeLocation" v-on:refreshdata="getAirQualityData()" v-on:cleardata="clearData" />
            <v-main
                class="grey lighten-4 mt-10"
                v-touch="{
                    left: () => swipe('Left'),
                    right: () => swipe('Right'),
                    up: () => swipe('Up'),
                    down: () => swipe('Down'),
                }"
            >
                <v-container class="mt-5">
                    <transition name="custom-classes-transition" enter-active-class="animate__animated animate__fadeIn animate__faster" leave-active-class="animate__animated animate__fadeOut animate__faster" mode="out-in">
                        <router-view :user="user" :loading="loading" :aqdata="airQualityData" v-on:updateuser="updateUser" />
                    </transition>
                </v-container>
            </v-main>
            <v-dialog content-class="rounded-xl pa-0 ma-0 mx-5" class="" :width="computedDialogWidth" overlay v-model="changeLocationDialog" style="z-index: 9999 !important">
                <v-card class="rounded-xl primary pb-2 ma-0" :width="computedDialogWidth" style="overflow: hidden !important">
                    <v-row dense class="">
                        <v-col cols="12" xs="12" class="px-5 pt-4 d-flex align-center justify-center">
                            <div class="mt-2 white--text text-h5 font-weight-light">Change your Location</div>
                            <v-spacer />
                            <div><v-icon @click="changeLocationDialog = false" class="white--text">icons8-close</v-icon></div>
                        </v-col>
                        <v-col cols="12" xs="12" class="mt-0 mx-4 d-flex align-start justify-start">
                            <div class="accent--text body-2 d-flex align-center">
                                Current:
                                <div class="font-weight-bold">{{ currentPostcodeLocation }}</div>
                                <!-- <div v-if="user.userPostcode === currentPostcodeLocation">(Home)</div> -->
                            </div>
                        </v-col>

                        <v-col cols="12" xs="12" class="pa-5 d-flex align-center justify-center">
                            <div class="flex-grow-1"><v-text-field outlined background-color="white" v-model="newLocation" placeholder="Enter new postcode or locate" class="rounded-xl" hide-details="auto" clearable @clear="clearNewLocation()"></v-text-field></div>
                            <div>
                                <v-btn dense depressed large height="54" class="ml-1 rounded-lg" icon><v-icon large class="white--text">icons8-my-location</v-icon></v-btn>
                            </div>
                        </v-col>
                        <v-col cols="12" xs="12" class="px-5 d-flex align-center justify-start">
                            <div><v-checkbox color="third" v-model="saveAsDefault" hide-details="auto" class="pa-0 ma-0"></v-checkbox></div>
                            <div><div class="white--text">Save as default</div></div>
                        </v-col>
                        <v-col cols="12" xs="12" class="pa-5 d-flex align-center justify-center">
                            <div class="flex-grow-1 mr-1"><v-btn depressed block class="accent2 white--text rounded-xl text-h7 font-weight-bold" @click="updateLocationHome()">Go Home</v-btn></div>
                            <div class="flex-grow-1 ml-1"><v-btn depressed block class="accent accent2--text rounded-xl text-h7 font-weight-bold" @click="updateLocation()">Update</v-btn></div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-dialog>
            <app-footer :user="user" />
        </v-app>
    </div>
</template>

<script>
import AppFooter from "@/components/AppFooter.vue";
import AppHeader from "./components/AppHeader.vue";
export default {
    name: "App",
    components: {
        "app-footer": AppFooter,
        AppHeader,
    },
    metaInfo: {
        title: "TAPP from Think Air",
    },
    data: () => ({
        loading: false,
        currentPostcodeLocation: "",
        user: {
            userName: "",
            userEail: "",
            userTelephone: "",
            userPostcode: "",
        },
        // Dialog Items
        changeLocationDialog: false,
        newLocation: "",
        saveAsDefault: false,
        airQualityData: {
            aqi: 0,
            pm25: 0,
            pm10: 0,
            o3: 0,
            no2: 0,
            so2: 0,
            co: 0,
        },
    }),
    computed: {
        // Return Shortened Postcode
        // computedPostcode() {
        //     if (this.user.userPostcode !== undefined && this.user.userPostcode !== null && this.user.userPostcode !== "") {
        //         return this.user.userPostcode.split(" ")[0];
        //     } else {
        //         return "";
        //     }
        // },
        computedDialogWidth() {
            if (this.$vuetify.breakpoint.xsOnly) {
                return "100%";
            } else {
                return "500px";
            }
        },
    },
    methods: {
        // Touch Swipe Support
        swipe(direction) {
            switch (true) {
                // Running Order
                // index | forecast | exposure | resources
                case (direction === "Left" && this.$route.name === "index"):
                    this.$router.push({ name: "forecast" });
                    break;
                case (direction === "Left" && this.$route.name === "forecast"):
                    this.$router.push({ name: "exposure" });
                    break;
                case (direction === "Left" && this.$route.name === "exposure"):
                    this.$router.push({ name: "resources" });
                    break;
                case (direction === "Right" && this.$route.name === "forecast"):
                    this.$router.push({ name: "index" });
                    break;
                case (direction === "Right" && this.$route.name === "exposure"):
                    this.$router.push({ name: "forecast" });
                    break;
                case (direction === "Right" && this.$route.name === "resources"):
                    this.$router.push({ name: "exposure" });
                    break;

            }
        },
        // Change - Location
        changeLocation() {
            this.saveAsDefault = false;
            this.changeLocationDialog = true;
        },
        // Update - Location
        updateLocation() {
            this.MIX_writeToLocalStorage("TAPP_currentPostcode", this.newLocation);
            this.currentPostcodeLocation = this.newLocation;
            if (this.saveAsDefault) {
                this.user.userPostcode == this.newLocation;
                this.MIX_writeToLocalStorage("TAPP_currentUser", JSON.stringify(this.user));
            }
            this.changeLocationDialog = false;
        },
        // Update - Location - Home
        updateLocationHome() {
            this.MIX_writeToLocalStorage("TAPP_currentPostcode", this.user.userPostcode);
            this.currentPostcodeLocation = this.user.userPostcode;
            this.changeLocationDialog = false;
        },
        // Clear - New Location
        clearNewLocation() {
            setTimeout(() => {
                this.newLocation = "";
            }, 1);
        },
        updateUser(user) {
            this.user = user;
            this.currentPostcodeLocation = this.user.userPostcode;
        },
        // Get Current Location from Device
        async getCurrentLocation() {
            let t = this;
            t.loading = true;
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(async (position) => {
                    const postcode = await t.$axios.get(`https://api.postcodes.io/postcodes?lon=${position.coords.longitude}&lat=${position.coords.latitude}`);
                    return postcode.data.result[0].postcode;
                });
            }
        },
        async getLocationData(postcode) {
            let t = this;
            t.loading = true;
            const airQualityData = await t.MIX_getLocationData("ta", postcode);

            if (airQualityData !== {}) {
                location.updated = this.$moment().format("x");
                //* Save to indexeddb
                this.MIX_writeToLocalStorage("TAPP_airQualityData", JSON.stringify(airQualityData));

                //await this.$db.saveData(location);
                // const check = t.checkPM25Value(parseInt(location.pm25));
                // t.searchedLocation = location;
                // t.airQualitySeries = [location.pm25];
                // t.airQualityValue = check;
            }
        },
        async getAirQualityData() {
            return new Promise((resolve, reject) => {
                this.loading = true;
                let t = this;
                    let AURNData = null;
                    let TAData = null;
                    let PCMData = null;
                    let COPData = null;
                    let METData = null;
                    (async () => {
                    try {

                        AURNData = await t.MIX_getLocationData("aurn", t.currentPostcodeLocation);
                        AURNData.updated = this.$moment().format("X");
                        this.MIX_writeToLocalStorage("TAPP_AURNData", JSON.stringify(AURNData));
                        TAData = await this.MIX_getLocationData("ta", this.currentPostcodeLocation);
                        TAData.updated = this.$moment().format("X");
                        this.MIX_writeToLocalStorage("TAPP_TAData", JSON.stringify(TAData));
                        PCMData = await this.MIX_getLocationData("pcm", this.currentPostcodeLocation);
                        PCMData.updated = this.$moment().format("X");
                        this.MIX_writeToLocalStorage("TAPP_PCMData", JSON.stringify(PCMData));
                        COPData = await this.MIX_getLocationData("cop", this.currentPostcodeLocation);
                        COPData.updated = this.$moment().format("X");
                        this.MIX_writeToLocalStorage("TAPP_COPData", JSON.stringify(COPData));

                        METData = await this.MIX_getMetLocationData( this.currentPostcodeLocation);
                        METData.updated = this.$moment().format("X");
                        this.MIX_writeToLocalStorage("TAPP_METData", JSON.stringify(METData));

                        // airQualityDataResult = await this.MIX_getLocationData("aurn", this.currentPostcodeLocation);
                        // console.log("airQualityDataResult = ", JSON.stringify(airQualityDataResult, null, 2));
                        // airQualityData = JSON.parse(airQualityDataResult);
                        // console.log('AURN Status', AURNData.status);
                        // console.log('TA Status', TAData.status);
                        // console.log('PCM Status', PCMData.status);
                        // console.log('COP Status', COPData.status);
                        this.loading = false;
                        resolve({ code: 1, message: 'Success', data: null, error: null });
                    } catch (error) {
                        this.loading = false;
                        reject(error);
                    }
                })();
            });
        },
        clearData() {
            this.MIX_deleteFromLocalStorage("TAPP_AURNData");
            this.MIX_deleteFromLocalStorage("TAPP_TAData");
            this.MIX_deleteFromLocalStorage("TAPP_PCMData");
            this.MIX_deleteFromLocalStorage("TAPP_COPData");
            this.MIX_deleteFromLocalStorage("TAPP_METData");
            this.MIX_deleteFromLocalStorage("TAPP_currentPostcode");
            this.MIX_deleteFromLocalStorage("TAPP_currentUser");
        }
    },
    async created() {
        this.loading = true;
        let userReadResult = this.MIX_readFromLocalStorage("TAPP_currentUser");
        this.user = JSON.parse(userReadResult);
        this.currentPostcodeLocation = this.MIX_readFromLocalStorage("TAPP_currentPostcode");
        // console.log("currentPostcodeLocation = ", this.currentPostcodeLocation);
        // let start = this.$moment().format("x");
        // console.log(this.$moment().format("HH:mm:ss"));
        // let allData = await this.MIX_getLocationData("all", this.currentPostcodeLocation);
        // let finish = this.$moment().format("x");
        // // calculate time taken using moment duration
        // let duration = this.$moment.duration(finish - start);
        // console.log("alldata = " + JSON.stringify(allData, null, 2));
        // console.log("Duration = ", duration.asSeconds());

        try {
            let dataSourceOnline = [];
            // Get Air Quality Data from Local Storage (if it exists)
            let AURNDataLocal = await this.MIX_readFromLocalStorage("TAPP_AURNData");
            let TADataLocal = await this.MIX_readFromLocalStorage("TAPP_TAData");
            let PCMDataLocal = await this.MIX_readFromLocalStorage("TAPP_PCMData");
            let COPDataLocal = await this.MIX_readFromLocalStorage("TAPP_COPData");
            let METDataLocal = await this.MIX_readFromLocalStorage("TAPP_METData");

            // If Status is Online then

            // console.log('AURNDataLocal', JSON.stringify(AURNDataLocal, null, 2));
            // console.log('TADataLocal', JSON.stringify(TADataLocal, null, 2));
            // console.log('PCMDataLocal', JSON.stringify(PCMDataLocal, null, 2));
            // console.log('COPDataLocal', JSON.stringify(COPDataLocal, null, 2));

            let todaysDate = this.$moment().format("X");

            if (AURNDataLocal?.status === 'online' && AURNDataLocal?.postcode === this.currentPostcodeLocation && AURNDataLocal?.updated === todaysDate) { dataSourceOnline.push('AURN'); }
            if (TADataLocal?.status === 'online' && TADataLocal?.postcode === this.currentPostcodeLocation && TADataLocal?.updated === todaysDate) { dataSourceOnline.push('TA'); }
            if (PCMDataLocal?.status === 'online' && PCMDataLocal?.postcode === this.currentPostcodeLocation && PCMDataLocal?.updated === todaysDate) { dataSourceOnline.push('PCM'); }
            if (COPDataLocal?.status === 'online' && COPDataLocal?.postcode === this.currentPostcodeLocation && COPDataLocal?.updated === todaysDate) { dataSourceOnline.push('COP'); }
            if (METDataLocal?.status === 'online' && METDataLocal?.postcode === this.currentPostcodeLocation && METDataLocal?.updated === todaysDate) { dataSourceOnline.push('MET'); }

            // console.log('dataSourceOnline.length' + dataSourceOnline.length)

            //let lastUpdated = this.$moment(airQualityData.updated, 'x').format("DD-MM-YYYY");
            // var airQualityDataResult = null

            switch (true) {
                case (dataSourceOnline.length === 4):
                    // console.log('all sources available')
                    // otherwise get the data from the api
                    break;
                case (dataSourceOnline.length === 0):
                    // Not enough sources get new data
                    await this.getAirQualityData()
                    break;

                    // case (airQualityData?.status === "online" && today === lastUpdated && this.currentPostcodeLocation === airQualityData?.postcode):
                //     this.airQualityData = airQualityData;
                //     break;
                default:
                    // airQualityDataResult = await this.MIX_getLocationData("pcm", this.currentPostcodeLocation);
                    // if (airQualityDataResult.status === "online") {
                    //     airQualityDataResult.updated = this.$moment().format("x");
                    //     let pm25Index = this.MIX_getPM25ConsensusData(airQualityDataResult.pm25);
                    //     console.log('pm25Index = ', pm25Index);
                    //     this.airQualityData = airQualityDataResult;
                    //     this.MIX_writeToLocalStorage("TAPP_airQualityData", JSON.stringify(airQualityDataResult));
                    // }
                    break;
            }
        } catch (error) {
            console.error(error.message);
        }
        // console.log('airQualityDataLocalResult = ', JSON.stringify(airQualityDataLocalResult));

        // console.log('this.currentPostcodeLocation = ', this.currentPostcodeLocation);

        // this.loading = false;
        // console.log('user = ', JSON.stringify(this.user, null, 2));
        // this.currentPostcodeLocation = this.user.userPostcode; // need to take this from local storage

        // check indexDB for latest data for current postcode
        // const airQualityStats = await this.$db.getData("latestLocation");
        // console.log('airQualityStats.postcode = ', airQualityStats.postcode)
        // console.log('this.currentPostcodeLocation = ', this.currentPostcodeLocation);
        // if (airQualityStats.postcode === this.currentPostcodeLocation) {
        //     // use this data
        //     console.log('User Local Data');
        // } else {
        //     // get new data
        //     console.log('Get New Data')
        //     this.getLocationData(this.currentPostcodeLocation);
        // }
        // console.log("airQualityStats = ", JSON.stringify(airQualityStats, null, 2));

        // let locationData = this.getLocationData(this.user.userPostcode);
        // console.log("locationData", JSON.stringify(locationData, null, 2));
        // this.currentPostcodeLocation = this.getCurrentLocation();
        //console.log(this.getCurrentLocation());
        // Read or Write to local storage
    },
};
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    background-color: #F5F5F5 !important;
}
.fullwidth {
    width: 100% !important;
    /* border: 1px solid red !important;; */
}
.v-banner__wrapper {
    border-bottom: 0px !important;
}
</style>
