<!--
CheckNetwork

Checks if the user is connected to the internet and shows a RED banner warning the user.
Checks if the user has a slow internet connection (2g or slow-2g) and shows an ORANGE banner warning the user.

 -->
<template>
    <div>
        <v-overlay v-if="!onLine" opacity="0.7" z-index="99998" />
        <transition name="custom-classes-transition" enter-active-class=" animate__animated animate__slideInUp animate__faster" leave-active-class="animate__animated animate__slideOutDown animate__faster" mode="out-in">
            <v-alert v-if="!onLine" class="error rounded-xl d-flex rounded-0" style="height: 80px !important">
                <div class="d-flex align-center">
                    <div><v-icon class="white--text animate__animated animate__tada animate__infinite">icons8-wi-fi-off</v-icon></div>
                    <div class="white--text ml-5 body-2">This app requires a <strong>WIFI / Data</strong> connection. You are currently offline.</div>
                </div>
            </v-alert>
        </transition>
        <transition name="custom-classes-transition" enter-active-class=" animate__animated animate__slideInUp animate__faster" leave-active-class="animate__animated animate__slideOutDown animate__faster" mode="out-in">
            <v-alert v-if="onLine && networkType && (networkType === '2g' || networkType === 'slow-2g')" style="height: 80px !important" class="warning d-flex rounded-xl">
                <div class="d-flex align-center">
                    <div><v-icon class="white--text animate__animated animate__tada animate__infinite">icons8-low-connection</v-icon></div>
                    <div class="white--text ml-5 body-2">This app requires a good <strong>WIFI / Data</strong> connection. Your WIFI is slow, some things might not work.</div>
                </div>
            </v-alert>
        </transition>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // Detect if user has an internet connection
            onLine: navigator.onLine,
            // Detect network connection, eg. 2g / slow-2g
            networkType: false,
        };
    },
    methods: {
        onConnectionChange() {
            this.networkType = navigator.connection.effectiveType;
        },
        updateOnlineStatus(e) {
            const { type } = e;
            this.onLine = type === "online";
        },
    },
    mounted() {
        //console.log( 'navigator.onLine = ' +  navigator.onLine)
        window.addEventListener("online", this.updateOnlineStatus);
        window.addEventListener("offline", this.updateOnlineStatus);
        if (navigator.connection) {
            this.networkType = navigator.connection.effectiveType;
            navigator.connection.addEventListener("change", this.onConnectionChange);
        }
    },
    beforeDestroy() {
        window.removeEventListener("online", this.updateOnlineStatus);
        window.removeEventListener("offline", this.updateOnlineStatus);
        if (navigator.connection) {
            this.networkType = navigator.connection.effectiveType;
            navigator.connection.removeEventListener("change", this.onConnectionChange);
        }
    },
};
</script>

<style scoped>
.v-alert {
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    bottom: 0 !important;
    margin-bottom: 0 !important;
    padding: 16px !important;
    position: fixed !important;
    width: 100% !important;
    z-index: 99999 !important;
}

.v-alert .v-icon {
    align-self: center !important;
    font-size: 32px !important;
}
</style>
