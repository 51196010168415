<template>

    <div>

        <!--Page Tittle-->
        <div class="text-center text-h5 primary--text font-weight-light">My Profile</div> 
         
        <v-card class="mt-4 pa-4 elevation-0 rounded-lg">
            
            <div class="text-h7 primary--text">
                This is your profile.
                From here you can update your personal details.
            </div>

            <v-divider class="mt-2 mb-2"></v-divider>

            <v-form>

                <div class="mt-4">
                    <v-text-field solo flat append-icon="icons8-name" background-color="background" label="Full Name" v-model="userForm.userName" 
                    class="rounded-xl" hide-details="auto" :error="errors.userName" :error-messages="errors.userNameErrorMessage"/>                    
                </div>

                <div class="mt-4">
                    <v-text-field solo flat append-icon="icons8-mail" background-color="background" label="Email" v-model="userForm.userEmail" 
                    class="rounded-xl" hide-details="auto" :error="errors.userEmail" :error-messages="errors.userEmailErrorMessage"/>
                </div>

                <div class="mt-4">
                    <v-text-field solo flat append-icon="icons8-phone" background-color="background" label="Mobile Number" v-model="userForm.userTelephone" 
                    class="rounded-xl" hide-details="auto" :error="errors.userTelephone" :error-messages="errors.userTelephoneErrorMessage"/>
                </div>

                <div class="mt-4">
                    <v-text-field solo flat append-icon="icons8-location" background-color="background" label="Postcode" v-model="userForm.userPostcode" 
                    class="rounded-xl" hide-details="auto" :error="errors.userPostcode" :error-messages="errors.userPostcodeErrorMessage"/>
                </div>

                <v-btn depressed block class="mt-4 primary rounded-xl text-h7 font-weight-bold accent--text" large height="50" @click="validateForm()">update</v-btn>
            
            </v-form>

        </v-card>
        
    </div>

</template>

<script>

export default {

    name: "ProfileView",

    data: () => ({

        userForm:{},
        
        errors: {
            userName: false,
            userNameErrorMessage: '',
            userEmail: false,
            userEmailErrorMessage: '',
            userTelephone: false,
            userTelephoneErrorMessage: '',
            userPostcode: false,
            userPostcodeErrorMessage: '',
        }
    }),
    methods: {
        // * Validate Form
        validateForm(){
            const t = this
            const emailRegex = /.+@.+\..+/
            // const postcodeRegex = /^[a-zA-Z]{1,2}[0-9]{1,2}[a-zA-Z]?[0-9][a-zA-Z]{2}$/

            t.errors.userName = false
            t.errors.userNameErrorMessage = ''
            t.errors.userEmail = false
            t.errors.userEmailErrorMessage = ''
            t.errors.userTelephone = false
            t.errors.userTelephoneErrorMessage = ''
            t.errors.userPostcode = false
            t.errors.userPostcodeErrorMessage == ''

            // Name
            if (!t.userForm.userName.trim()) {
                t.errors.userName = true
                t.errors.userNameErrorMessage = 'Name required '
            }
            // Must be between 2 and 60 characters
            else if (t.userForm.userName.trim().length < 2 || t.userForm.userName.trim().length > 60) {
                t.errors.userName = true
                t.errors.userNameErrorMessage = 'Name must be between 2 and 60 characters'
            }

            // Email
            if (!t.userForm.userEmail.trim()) {
                t.errors.userEmail = true
                t.errors.userEmailErrorMessage = 'Email required'
            }
            // Must be a (simple) valid email address
            else if (!emailRegex.test(t.userForm.userEmail)) {
                t.errors.userEmail = true
                t.errors.userEmailErrorMessage = 'Email not valid'
            }
            // User Contact Telephone
            if (!t.userForm.userTelephone.trim()) {
                t.errors.userTelephone = true
                t.errors.userTelephoneErrorMessage = 'Telephone Number required'
            }
            // Landline number must start 01, 02 or 03 and be either 10 or 11 digits
            else if (['1', '2', '3'].includes(t.userForm.userTelephone.trim()[1]) && (t.userForm.userTelephone.trim().length < 10 || t.userForm.userTelephone.trim().length > 11)) {
                t.errors.userTelephone = true
                t.errors.userTelephoneErrorMessage = 'Landline numbers must have either 10 or 11 digits'
            }
            // Mobile number must start 07 and be 11 digits
            else if (['7'].includes(t.userForm.userTelephone.trim()[1]) && t.userForm.userTelephone.trim().length !== 11) {
                t.errors.userTelephone = true
                t.errors.userTelephoneErrorMessage = 'Mobile numbers must have 11 digits'
            }
            // Number must start 01, 02, 03 or 07
            else if (!['0'].includes(t.userForm.userTelephone[0]) || ['0', '4', '5', '6', '8', '9'].includes(t.userForm.userTelephone[1])) {
                t.errors.userTelephone = true
                t.errors.userTelephoneErrorMessage = 'Landline numbers start 01, 02 or 03. Mobile numbers must start 07'
            }

            // Address Postcode
            if (!t.userForm.userPostcode.trim()) {
                t.errors.userPostcode = true
                t.errors.userPostcodeErrorMessage = 'Postcode required'
            } 
            
            //Must be a valid UK POSTCODE
            // else if (!postcodeRegex.test(t.userForm.userPostcode)) {
            //     t.errors.userPostcode = true
            //     t.errors.userPostcodeErrorMessage = 'Postcode not valid'
            // }

            // Check if there any errors left
            if (!Object.values(t.errors).includes(true)) {
                t.updateProfile()
            }
        },

        // * Update user's details in the and in the local storage
        async updateProfile(){
            let updateResult = await this.MIX_firestore_update(this.userForm, 'users', this.userForm)
            if (updateResult.code === 1) {
                this.MIX_writeToLocalStorage("TAPP_currentUser", JSON.stringify(this.userForm));
                this.MIX_writeToLocalStorage('TAPP_currentPostcode', this.userForm.userPostcode);
                this.MIX_go('/');
            }
        },
        
        // * Read Current user's data
        async readCurrentUser(){
            let userReadResult = await localStorage.getItem("TAPP_currentUser");
            this.userForm = JSON.parse(userReadResult);
        }
    },
    async created() {
        this.readCurrentUser();
    }
};
</script>

