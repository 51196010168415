<template>
    <div v-if="user !== null && user !== undefined">
        <div class="footerbanner"></div>
        <v-bottom-navigation app elevation-0 class="primary rounded-xl" grow style="z-index: 99 !important; height: 80px !important" height="80" background-color="white" dark >
            <v-btn icon large color="white" active-class="white--text" @click="MIX_go('/')">
                <div class="mt-2 white--text">Index</div>
                <v-icon class="white--text" large>icons8-speedometer</v-icon>
            </v-btn>
            <v-btn icon large color="white" active-class="white--text" @click="MIX_go('/forecast')">
                <div class="mt-2 white--text">Forecast</div>
                <v-icon class="white--text" large>icons8-calendar-3</v-icon>
            </v-btn>
            <v-btn icon large color="white" active-class="white--text" @click="MIX_go('/exposure')">
                <div class="mt-2 white--text">Exposure</div>
                <v-icon class="white--text" large>icons8-cloud-business</v-icon>
            </v-btn>
            <v-btn icon large color="white" active-class="white--text" @click="MIX_go('/resources')">
                <div class="mt-2 white--text">Resources</div>
                <v-icon class="white--text" large>icons8-info</v-icon>
            </v-btn>
        </v-bottom-navigation>
    </div>
</template>

<script>
export default {
    name: "app-footer",
    props: {
        user: {
            type: Object,
            default: null,
        },
    }
};
</script>

<style scoped>
    .rounded-xl {
        border-bottom-left-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }
.footerbanner {
    z-index: 99 !important;
    bottom: 0px;
    width: 100vw !important;
    position: fixed !important;
    margin-bottom: 80px !important;
    height: 30px !important;
    background: -moz-linear-gradient(top, rgb(243, 243, 244) 30%, rgba(243, 243, 244, 0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgb(243, 243, 244) 30%, rgba(243, 243, 244, 0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to top, rgb(243, 243, 244) 30%, rgba(243, 243, 244, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
</style>
