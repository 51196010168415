<template>
    <div>
        <v-tabs v-model="selectedDay" fixed-tabs class="px-4">
            <v-tab :href="'#today'">{{ forecastDates.today.day }}</v-tab>
            <v-tab :href="'#tomorrow'">{{ forecastDates.tomorrow.day }}</v-tab>
            <v-tab :href="'#dayAfterTomorrow'">{{ forecastDates.dayAfterTomorrow.day }}</v-tab>
        </v-tabs>
        <v-simple-table class="px-4" @touchstart.native.stop
    @touchmove.native.stop
    @touchend.native.stop>
            <template v-slot:default>
                <tbody>
                    <tr class="grey lighten-3">
                        <td><div class="forecastblock" :class="isCurrentTime(0)">01:00</div></td>
                        <td><div class="forecastblock" :class="isCurrentTime(1)">02:00</div></td>
                        <td><div class="forecastblock" :class="isCurrentTime(2)">03:00</div></td>
                        <td><div class="forecastblock" :class="isCurrentTime(3)">04:00</div></td>
                        <td><div class="forecastblock" :class="isCurrentTime(4)">04:00</div></td>
                        <td><div class="forecastblock" :class="isCurrentTime(5)">05:00</div></td>
                        <td><div class="forecastblock" :class="isCurrentTime(6)">06:00</div></td>
                        <td><div class="forecastblock" :class="isCurrentTime(7)">07:00</div></td>
                        <td><div class="forecastblock" :class="isCurrentTime(8)">08:00</div></td>
                        <td><div class="forecastblock" :class="isCurrentTime(9)">09:00</div></td>
                        <td><div class="forecastblock" :class="isCurrentTime(10)">10:00</div></td>
                        <td><div class="forecastblock" :class="isCurrentTime(11)">11:00</div></td>
                        <td><div class="forecastblock" :class="isCurrentTime(12)">12:00</div></td>
                        <td><div class="forecastblock" :class="isCurrentTime(13)">13:00</div></td>
                        <td><div class="forecastblock" :class="isCurrentTime(14)">14:00</div></td>
                        <td><div class="forecastblock" :class="isCurrentTime(15)">15:00</div></td>
                        <td><div class="forecastblock" :class="isCurrentTime(16)">16:00</div></td>
                        <td><div class="forecastblock" :class="isCurrentTime(17)">17:00</div></td>
                        <td><div class="forecastblock" :class="isCurrentTime(18)">18:00</div></td>
                        <td><div class="forecastblock" :class="isCurrentTime(19)">19:00</div></td>
                        <td><div class="forecastblock" :class="isCurrentTime(20)">20:00</div></td>
                        <td><div class="forecastblock" :class="isCurrentTime(21)">21:00</div></td>
                        <td><div class="forecastblock" :class="isCurrentTime(22)">22:00</div></td>
                        <td><div class="forecastblock" :class="isCurrentTime(23)">23:00</div></td>
                        <td><div class="forecastblock" :class="isCurrentTime(24)">00:00</div></td>
                    </tr>
                    <tr class="grey lighten-3">
                        <td align="center">
                            <div class="forecastblock" :class="getForecastColourPerHour(0)">{{ getForecastDataPerHour(0) }}</div>
                        </td>
                        <td align="center">
                            <div class="forecastblock" :class="getForecastColourPerHour(1)">{{ getForecastDataPerHour(1) }}</div>
                        </td>
                        <td align="center">
                            <div class="forecastblock" :class="getForecastColourPerHour(2)">{{ getForecastDataPerHour(2) }}</div>
                        </td>
                        <td align="center">
                            <div class="forecastblock" :class="getForecastColourPerHour(3)">{{ getForecastDataPerHour(3) }}</div>
                        </td>
                        <td align="center">
                            <div class="forecastblock" :class="getForecastColourPerHour(4)">{{ getForecastDataPerHour(4) }}</div>
                        </td>
                        <td align="center">
                            <div class="forecastblock" :class="getForecastColourPerHour(5)">{{ getForecastDataPerHour(5) }}</div>
                        </td>
                        <td align="center">
                            <div class="forecastblock" :class="getForecastColourPerHour(6)">{{ getForecastDataPerHour(6) }}</div>
                        </td>
                        <td align="center">
                            <div class="forecastblock" :class="getForecastColourPerHour(7)">{{ getForecastDataPerHour(7) }}</div>
                        </td>
                        <td align="center">
                            <div class="forecastblock" :class="getForecastColourPerHour(8)">{{ getForecastDataPerHour(8) }}</div>
                        </td>
                        <td align="center">
                            <div class="forecastblock" :class="getForecastColourPerHour(9)">{{ getForecastDataPerHour(9) }}</div>
                        </td>
                        <td align="center">
                            <div class="forecastblock" :class="getForecastColourPerHour(10)">{{ getForecastDataPerHour(10) }}</div>
                        </td>
                        <td align="center">
                            <div class="forecastblock" :class="getForecastColourPerHour(11)">{{ getForecastDataPerHour(11) }}</div>
                        </td>
                        <td align="center">
                            <div class="forecastblock" :class="getForecastColourPerHour(12)">{{ getForecastDataPerHour(12) }}</div>
                        </td>
                        <td align="center">
                            <div class="forecastblock" :class="getForecastColourPerHour(13)">{{ getForecastDataPerHour(13) }}</div>
                        </td>
                        <td align="center">
                            <div class="forecastblock" :class="getForecastColourPerHour(14)">{{ getForecastDataPerHour(14) }}</div>
                        </td>
                        <td align="center">
                            <div class="forecastblock" :class="getForecastColourPerHour(15)">{{ getForecastDataPerHour(15) }}</div>
                        </td>
                        <td align="center">
                            <div class="forecastblock" :class="getForecastColourPerHour(16)">{{ getForecastDataPerHour(16) }}</div>
                        </td>
                        <td align="center">
                            <div class="forecastblock" :class="getForecastColourPerHour(17)">{{ getForecastDataPerHour(17) }}</div>
                        </td>
                        <td align="center">
                            <div class="forecastblock" :class="getForecastColourPerHour(18)">{{ getForecastDataPerHour(18) }}</div>
                        </td>
                        <td align="center">
                            <div class="forecastblock" :class="getForecastColourPerHour(19)">{{ getForecastDataPerHour(19) }}</div>
                        </td>
                        <td align="center">
                            <div class="forecastblock" :class="getForecastColourPerHour(20)">{{ getForecastDataPerHour(20) }}</div>
                        </td>
                        <td align="center">
                            <div class="forecastblock" :class="getForecastColourPerHour(21)">{{ getForecastDataPerHour(21) }}</div>
                        </td>
                        <td align="center">
                            <div class="forecastblock" :class="getForecastColourPerHour(22)">{{ getForecastDataPerHour(22) }}</div>
                        </td>
                        <td align="center">
                            <div class="forecastblock" :class="getForecastColourPerHour(23)">{{ getForecastDataPerHour(23) }}</div>
                        </td>
                        <td align="center">
                            <div class="forecastblock" :class="getForecastColourPerHour(24)">{{ getForecastDataPerHour(24) }}</div>
                        </td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>

<script>
export default {
    name: "ForecastTable",
    props: {
        forecastdata: {
            type: Object,
            default: () => {
                return {};
            },
        },
        forecastDates: {
            type: Object,
            default: () => {
                return {};
            },
        },
        datatype: {
            type: String,
            default: '',
        },
        thresholdtype: {
            type: String,
            default: '',
        },
    },
    data: () => ({
        selectedDay: "today",
    }),
    methods: {
        isCurrentTime(value) {
            // get nearest hour
            const now = new Date();
            const currentHour = now.getHours();
            const currentDay = this.$moment().format("ddd");
            if (value === currentHour && this.forecastDates[this.selectedDay].day === currentDay) {
                return "now";
            } else {
                return "";
            }
        },
        getForecastColourPerHour(value) {
            let time = this.$moment(this.forecastDates[this.selectedDay].startTimeUnix, "X").add(value, "hour").format("X");
            let result = parseInt(this.forecastdata[time]);
            // console.log('result = ' + result);
            if (isNaN(result)) { result = 0;}
            // console.log('result = ' + result);
            // console.log(JSON.stringify(this.thresholds[this.datatype]))
            let threshold = this.thresholds[this.datatype].find((x) => {
                if (this.thresholdtype === 'minmax') {               
                    if (result >= x.min && result < x.max) {
                        return x;
                    }
                } else if (this.thresholdtype === 'index') {
                    if (result === x.index) {
                        return x;
                    }
                }
            });
            return threshold.class;
        },
        getForecastDataPerHour(value) {
            let time = this.$moment(this.forecastDates[this.selectedDay].startTimeUnix, "X").add(value, "hour").format("X");
            return parseInt(this.forecastdata[time]);
        },
    },
};
</script>

<style scoped>
td {
    padding: 0px !important;
    border: 1px solid white;
}
.forecastblock {
    width: 100%;
    height: 100%;
    padding: 10px;
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.now {
    background-color: #a37fef !important;
    color: white !important;
}
</style>
