<template>
    <div class="home">

        <!--Page Title-->
        <div class="text-center mt-5 text-h5 primary--text font-weight-light">
            Air Quality Resources
        </div>
        
        <!--Indoor AQ Monitor-->
        <v-card class="mt-4 elevation-0 rounded-lg pa-0">

            <div class="pt-4 pa-4 d-flex align-center fullwidth">

                <!--Header-->
                <div class="text-h7 font-weight-light grey--text text--darken-2">
                    Indoor AQ Monitor
                </div>

            </div>

            <div class="pa-4 d-flex align-start fullwidth justify-space-between" style="width: 100% !important">

                <!--Img-->
                <img src="@/assets/indoor_aq_monitor.png" alt="Indoor AQ Monitor" width="100"/>

                <div>
                    <!--List-->
                    <ul class="grey--text">
                        <li>Monitors PM2.5, CO2</li>
                        <li>LoraWAN Connectivity</li>
                    </ul>

                    <!--Contact Us Button-->
                    <div class="mt-4 text-right">

                        <v-btn 
                            block
                            class="accent rounded-xl"
                            depressed
                            @click="contactPredifine( 'Indoor AQ Monitor', 'Write an enquiry')"
                            >Contact Us
                        </v-btn>

                    </div>

                </div>

            </div>

            <!--Read More-->
            <v-expansion-panels color="primary" class="elevation-0 ma-0 pa-0" accordian flat tile>

                <v-expansion-panel class="white lighten-5">

                    <v-expansion-panel-header class="font-weight-bold primary--text">
                        Read More
                    </v-expansion-panel-header>

                    <v-expansion-panel-content class="grey--text text--darken-2" style="margin: 0px !important; padding: 0px !important"> 
                        
                        <div class="mt-4">
                            Think air offer a wide range of sensor solutions capable of measuring pollutant levels and displaying data live for through user dashboards. 
                            This allows users to further manage pollution exposure for both indoor and outdoor air quality. Use the contact us button for more information.
                        </div>
                    </v-expansion-panel-content>

                </v-expansion-panel>

            </v-expansion-panels>

        </v-card>

        <!--Indoor Air Purifier-->
        <v-card class="mt-4 elevation-0 rounded-lg pa-0">

            <div class="pt-4 pa-4 d-flex align-center fullwidth">

                <!--Header-->
                <div class="text-h6 font-weight-light grey--text text--darken-2">
                    Indoor Air Purifier
                </div>

            </div>

            <div class="pa-4 d-flex align-start fullwidth justify-space-between" style="width: 100% !important">
                
                <!--Img-->
                <img src="@/assets/medicair.png" alt="Indoor AQ Monitor" width="100"/>

                <div>
                    <!--List-->
                    <ul class="grey--text">
                        <li>Cleans Air really well</li>
                        <li>More Text To Go Here</li>
                    </ul>

                    <!--Contact Us Button-->
                    <div class="mt-4 text-right">

                        <v-btn 
                            block 
                            class="accent rounded-xl"
                            depressed 
                            @click="contactPredifine('Indoor Air Purifier' , 'Write an enquiry')"
                            >Contact Us
                        </v-btn>

                    </div>

                </div>

            </div>

            <!--Read More-->
            <v-expansion-panels color="primary" class="elevation-0 ma-0 pa-0" accordian flat tile>
                
                <v-expansion-panel class="white lighten-5">

                    <v-expansion-panel-header class="font-weight-bold primary--text">
                        Read More
                    </v-expansion-panel-header>

                    <v-expansion-panel-content class="grey--text text--darken-2" style="margin: 0px !important; padding: 0px !important"> 
                        
                        <div class="mt-4">
                            Think air with partners medicair offer a range of automatic indoor air purifiers. 
                            These systems can automatically ensure an indoor environment is within safe limits for patients. 
                            Use the contact us button for more information
                        </div>
                    
                    </v-expansion-panel-content>

                </v-expansion-panel>

            </v-expansion-panels>

        </v-card>

        <!--Air Quality Links-->
        <v-card class="mt-4 elevation-0 rounded-lg pa-0">

            <!--Header-->
            <div class="pt-4 pa-4 d-flex align-center fullwidth">

                <div class="text-h6 font-weight-light grey--text text--darken-2">
                    Air Quality Links
                </div>

            </div>

            <!--Asthma and Lunk UK-->
            <div class="pa-4 d-flex align-center fullwidth justify-space-between" style="width: 100% !important">

                <img src="@/assets/asthmaandlunguk.png" alt="Asthma & Lung UK" width="100" />
                
                <div class="ml-4" style="width: 50% !important">

                    <div class="text-h7 primary--text font-weight-bold">

                        <a href="https://www.asthmaandlung.org.uk/" target="_blank">Asthma + Lung UK</a>

                    </div>

                    <div class="grey--text body-2">

                        The nation's lung charity fighting for a world where everyone can breathe freely.
                        Contains useful information to improve disease management.

                    </div>

                </div>

            </div>

            <!--COPD News Today-->
            <div class="pa-4 d-flex align-center fullwidth justify-space-between" style="width: 100% !important">

                <img src="@/assets/copdnewstoday.png" alt="Asthma & Lung UK" width="100"/>

                <div class="ml-4"  style="width: 50% !important">

                    <div class="text-h7 primary--text font-weight-bold">
                        
                        <a href="https://copdnewstoday.com/" target="_blank">COPD News Today</a>
                    
                    </div>

                    <div class="grey--text body-2">
                        
                        COPD News Today is a digital platform intended to provide the chronic obstructive 
                        on COPD.
                    
                    </div>
                </div>
            </div>

            <!--Healtline-->
            <div class="pa-4 d-flex align-center fullwidth justify-space-between" style="width: 100% !important">

                <img src="@/assets/heathlinelogo.png" alt="Asthma & Lung UK" width="100" />


                <div class="ml-4" style="width: 50% !important">

                    <div class="text-h7 primary--text font-weight-bold">
                        
                        <a href="https://copdnewstoday.com/" target="_blank">Healthline</a>

                    </div>
                    
                    <div class="grey--text body-2">
                        Healthline covers all facets of physical and mental health openly and objectively because we’re here for the whole person — for your whole life. 
                        And because people come to us with unique challenges, we listen closely and put empathy first across our organization. 
                        In everything from our personal perspectives to our commitment to inclusivity, you’ll see that we feel with you.
                    </div>

                </div>
            </div>

            <!--WHO-->
            <div class="pa-4 d-flex align-center fullwidth justify-space-between" style="width: 100% !important">
                
                <img src="@/assets/whologo.png" alt="Asthma & Lung UK" width="100" />

                <div class="ml-4"  style="width: 50% !important">

                    <div class="text-h7 primary--text font-weight-bold">

                        <a href="https://www.who.int/" target="_blank">WHO</a>

                    </div>

                    <div class="grey--text body-2">
                        The United Nations agency working to promote health, keep the world safe and serve the vulnerable.
                        WHO leads global efforts to expand universal health coverage. 
                        We direct and coordinate the world’s response to health emergencies. 
                        And we promote healthier lives – from pregnancy care through old age.
                        Our Triple Billion targets outline an ambitious plan for the world to achieve good health for all using science-based policies and programmes.
                    </div>

                </div>

            </div>
        </v-card>

        <!--News Feed-->
        <v-card class="mt-4 elevation-0 rounded-lg pa-0">

            <!-- <div class="pa-4 mb-4 d-flex align-center fullwidth"> -->

            <div class="pa-4 text-h6 font-weight-light grey--text text--darken-2">
                News Feed
            </div>
                
            <!-- </div> -->

            <div class="pa-4 d-flex align-center fullwidth  " style="width: 100% !important">
                
               
                <a class="twitter-timeline " 
                    data-chrome="nofooter noheader noscrollbar loader"
                    data-width="100%"
                    data-theme="light"
                    href="https://twitter.com/wethinkair?ref_src=twsrc%5Etfw">
                </a>
               
                <script async src="https://platform.twitter.com/widgets.js" 
                    charset="utf-8"
                    type="application/javascript">
                    
                </script>


            </div>
            
        </v-card>        
    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: "ResourcesView",

    methods: {

        async contactPredifine( subject, message){
            this.info = {}
            this.info.body = message
            this.info.subject = subject
            this.MIX_writeToLocalStorage('Contact_Info', JSON.stringify(this.info)  ) 
            this.MIX_go('contact-us'); 
        }
    }
};
</script>

<style scoped>
   
</style>
