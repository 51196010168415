<template>

    <div class="home">
        
        <!--Loader-->
        <pageLoader :is-page-loading="loading" class="loader"/>

        <!--Page Tittle-->
        <div class="text-center text-h5 primary--text font-weight-light">Air Quality Index</div>
        
        <!--Pm25Current-->
        <v-card class="mt-4 elevation-0 rounded-lg pa-0">

            <!--Header-->
            <div class="pt-4 px-4 d-flex align-center fullwidth justify-space-between">

                <div class="text-h6 font-weight-light grey--text text--darken-2">
                    Current Air Quality
                </div>
                
                <div>
                    <v-chip :class="pm25Current().class" class="pa-2 white--text font-weight-bold">{{ pm25Current().text }}</v-chip>
                </div>
            </div>

            <!--PM25Index Graph-->
            <div style="height: 250px !important">
                
                <guage-chart v-if="!loading" 
                class="mt-4" 
                :seriesdata="pm25Index()"/></div>
            
            <!--Read More-->
            <v-expansion-panels color="primary" class="elevation-0 ma-0 pa-0" accordian flat tile>

                <v-expansion-panel class="white lighten-5">

                    <v-expansion-panel-header class="font-weight-bold primary--text"> 
                        Read More
                    </v-expansion-panel-header>

                    <v-expansion-panel-content class="grey--text text--darken-2" style="margin: 0px !important; padding: 0px !important"> 
                        
                            <div class="mt-4">
                            The Current level gauge indicates the air quality index (TAQI) for air pollution predicted at your outdoor location.
                            The AQI is numbered 1-10 and divided into four bands, low (1) to very high (10).
                            </div>

                            <div class="mt-4 text-center">
                                <strong>Recommended Actions and Health Advice </strong>
                            </div>

                            <div class="mt-4"> 
                                <v-chip class="mr-4 green white--text font-weight-bold"> 1-3 </v-chip> Carry on with your usual outdoor activities. 
                            </div>
                            <div class="mt-4">
                                <v-chip class="mr-4 orange font-weight-bold"> 4-6 </v-chip> Consider reducing strenuous physical activities outdoors.
                            </div>
                            <div class="mt-4"> 
                                <v-chip class="mr-4 red font-weight-bold"> 7-9 </v-chip> You should avoid strenuous activities, particularly outdoors, and if you experience symptoms. 
                            </div>
                            <div class="mt-4">
                                <v-chip class="mr-5 primary font-weight-bold"> 10 </v-chip> Strenuous or tiring activities should be avoided where possible. Purification systems if available should be used to ensure a healthy indoor environment.
                            </div>

                    </v-expansion-panel-content>

                </v-expansion-panel>

            </v-expansion-panels>
            
        </v-card>

        <!--Pm256Hrs-->
        <v-card class="mt-4 elevation-0 rounded-lg pa-0">

            <!--Header-->
            <div class="pt-4 px-4 mb-4 d-flex align-center fullwidth">

                <div class="text-h6 font-weight-light grey--text text--darken-2">
                    Current Air Quality
                </div>
            </div>

            <!--PM256Hrs Bar Chart-->
            <div style="height: 250px !important">
                <bar-chart v-if="!loading" class="mt-4" :seriesdata="pm25Index6Hrs()" :xaxisdata="date6Hrs" /></div>
            
            <!--Read More-->
            <v-expansion-panels color="primary" class="elevation-0 ma-0 pa-0" accordian flat tile>

                <v-expansion-panel class="white lighten-5">

                    <v-expansion-panel-header class="font-weight-bold primary--text"> 
                        Read More 
                    </v-expansion-panel-header>

                    <v-expansion-panel-content class="grey--text text--darken-2" style="margin: 0px !important; padding: 0px !important"> 
                        
                        <div class="mt-4">
                            The current and forecast TAQI bar chart indicates the air quality expected at the user’s outdoor location while also showing predicted levels for the next 5 hours.
                        </div>

                    </v-expansion-panel-content>

                </v-expansion-panel>

            </v-expansion-panels>
            
        </v-card>

        <!--Current Pollutant Levels-->
        <v-card class="mt-4 elevation-0 rounded-lg pa-0">

            <!--Header-->
            <div class="pt-4 px-4 mb-4 d-flex align-center fullwidth">

                <div class="text-h6 font-weight-light grey--text text--darken-2">
                    Current Polutant Levels
                </div>
            </div>

            <!--Pollutant Levels Chart-->
            <div style="height: 250px !important">
                <v-simple-table>
                    <template v-slot:default>
                        <tbody>

                            <!--PM2.5-->
                            <tr>
                                <td width="33%" align="center">PM2.5</td>
                                <td width="20%" align="center">
                                    <v-chip class="white--text" :class="airQualityCurrent().pm25IndexCurrent.class">{{ airQualityCurrent().pm25IndexCurrent.index }}</v-chip>
                                </td>
                                <td width="33%" align="center">{{ airQualityCurrent().pm25IndexCurrent.value }}</td>
                            </tr>

                            <!--PM10-->
                            <tr>
                                <td width="33%" align="center">PM10</td>
                                <td width="33%" align="center">
                                    <v-chip class="white--text" :class="airQualityCurrent().pm10IndexCurrent.class">{{ airQualityCurrent().pm10IndexCurrent.index }}</v-chip>
                                </td>
                                <td width="33%" align="center">{{ airQualityCurrent().pm10IndexCurrent.value }}</td>
                            </tr>

                            <!--NO2-->
                            <tr>
                                <td width="33%" align="center">NO2</td>
                                <td width="33%" align="center">
                                    <v-chip class="white--text" :class="airQualityCurrent().no2IndexCurrent.class">{{ airQualityCurrent().no2IndexCurrent.index }}</v-chip>
                                </td>
                                <td width="33%" align="center">{{ airQualityCurrent().no2IndexCurrent.value }}</td>
                            </tr>

                            <!--Ozone-->
                            <tr>
                                <td width="33%" align="center">Ozone</td>
                                <td width="33%" align="center">
                                    <v-chip class="white--text" :class="airQualityCurrent().o3IndexCurrent.class">{{ airQualityCurrent().o3IndexCurrent.index }}</v-chip>
                                </td>
                                <td width="33%" align="center">{{ airQualityCurrent().o3IndexCurrent.value }}</td>
                            </tr>

                            <!--Pollen-->
                            <tr>
                                <td width="33%" align="center">Pollen</td>
                                <td width="33%" align="center"><v-chip class="white--text">-</v-chip></td>
                                <td width="33%" align="center">-</td>
                            </tr>

                        </tbody>
                    </template>
                </v-simple-table>
            </div>

            <!--Read More-->
            <v-expansion-panels color="primary" class="elevation-0 ma-0 pa-0" accordian flat tile>

                <v-expansion-panel class="white lighten-5">

                    <v-expansion-panel-header class="font-weight-bold primary--text"> 
                        Read More 
                    </v-expansion-panel-header>

                    <v-expansion-panel-content class="grey--text text--darken-2" style="margin: 0px !important; padding: 0px !important"> 
                        
                        <div class="mt-4">
                            This chart provides users with individual predicted pollutant levels in both TAQI format as well as concentration values. This format allows users to know what pollutant is likely to be causing the highest risk. 
                            We also provide predicted levels of pollen from forecast data.
                        </div>
                       
                       </v-expansion-panel-content>

                </v-expansion-panel>

            </v-expansion-panels>
           
        </v-card>

    </div>

</template>

<script>
    import GuageChart from "@/components/GuageChart.vue";
    // import BarChart from "@/components/BarChart.vue";


    export default {
        name: "HomeView",

        components: {
            GuageChart,
            // BarChart,
        },

        props: {
            user: {
                type: Object,
                default: () => {},
            },
            aqdata: {
                type: Object,
                default: () => {},
            },
            loading: {
                type: Boolean,
                default: false,
            },
        },

        data: () => ({
            seriesData: [{ value: 9 }],
        }),

        computed: {

            date6Hrs() {
                let data6Hrs = [];
                data6Hrs.push("Now");
                data6Hrs.push(
                    this.$moment()
                        .startOf("hour")
                        .add( 1 , "hours")
                        .format("HH:mm")
                );
                data6Hrs.push(
                    this.$moment()
                        .startOf("hour")
                        .add( 2 , "hours")
                        .format("HH:mm")
                );
                data6Hrs.push(
                    this.$moment()
                        .startOf("hour")
                        .add( 3 , "hours")
                        .format("HH:mm")
                );
                data6Hrs.push(
                    this.$moment()
                        .startOf("hour")
                        .add( 4 , "hours")
                        .format("HH:mm")
                );
                data6Hrs.push(
                    this.$moment()
                        .startOf("hour")
                        .add( 5 , "hours")
                        .format("HH:mm")
                );
                return data6Hrs;
            },
        },

        methods: {

            getThreshold(value, type, thresholdtype) {
                let threshold = this.thresholds[type].find((x) => {
                    if (thresholdtype === "minmax") {
                        if (value >= x.min && value < x.max) {
                            return x;
                        }
                    } else if (thresholdtype === "index") {
                        if (value === x.index) {
                            return x;
                        }
                    }
                });
                return threshold;
            },

            pm25Index6Hrs() {
                let pm25Index6Hrs = [];
                let COPDataLocalResult = this.MIX_readFromLocalStorage("TAPP_COPData");
                let COPDataLocal = COPDataLocalResult ? JSON.parse(COPDataLocalResult) : null;
                for (let i = 0; i <=5 ; i++) {
                    let nowDate = this.$moment()
                        .startOf("hour")
                        .add( i, "hours")
                        .format("X");

                    console.log( this.getThreshold(COPDataLocal.pm2p5_taqi[nowDate], "pm25", "index"))
                    pm25Index6Hrs.push({
                        value: COPDataLocal.pm2p5_taqi[nowDate],
                        itemStyle: {
                            color: this.getThreshold(COPDataLocal.pm2p5_taqi[nowDate], "pm25", "index").colour,
                        },
                    });
                }
                return pm25Index6Hrs;
            },

            getCurrentUnixTime() {
                const now = new Date();
                const currentHour = now.getHours();
                return this.$moment().startOf("day").add(currentHour, "hours").format("X");
            },

            pm25Index() {
                let COPDataLocalResult = this.MIX_readFromLocalStorage("TAPP_COPData");
                let COPDataLocal = COPDataLocalResult ? JSON.parse(COPDataLocalResult) : null;
                let pm25Index = COPDataLocal.pm2p5_taqi[this.getCurrentUnixTime()];
                return [{ value: pm25Index }];
            },

            // get current Air Quality Index / Status / And Text
            pm25Current() {
                // console.log("lets start here");
                let COPDataLocalResult = this.MIX_readFromLocalStorage("TAPP_COPData");
                let COPDataLocal = COPDataLocalResult ? JSON.parse(COPDataLocalResult) : null;
                // console.log("is there data ? ", JSON.stringify(COPDataLocal, null, 2));
                const now = new Date();
                const currentHour = now.getHours();
                let nowDate = this.$moment().startOf("hour").add(currentHour, "hours").format("X");
                let pm25Current = COPDataLocal.pm2p5_taqi[nowDate];

                return {
                    index: pm25Current,
                    color: this.getThreshold(COPDataLocal.pm2p5_taqi[nowDate], "pm25", "index").colour,
                    class: this.getThreshold(COPDataLocal.pm2p5_taqi[nowDate], "pm25", "index").class,
                    text: this.getThreshold(COPDataLocal.pm2p5_taqi[nowDate], "pm25", "index").text,
                };
            },

            airQualityCurrent() {
                let COPDataLocalResult = this.MIX_readFromLocalStorage("TAPP_COPData");
                let COPDataLocal = COPDataLocalResult ? JSON.parse(COPDataLocalResult) : null;
                const now = new Date();
                const currentHour = now.getHours();
                let nowDate = this.$moment().startOf("hour").add(currentHour, "hours").format("X");
                let pm25IndexCurrent = COPDataLocal.pm2p5_taqi[nowDate];
                let pm10IndexCurrent = COPDataLocal.pm10_taqi[nowDate];
                let no2IndexCurrent = COPDataLocal.no2_taqi[nowDate];
                let o3IndexCurrent = COPDataLocal.o3_taqi[nowDate];
                let pm25ValueCurrent = COPDataLocal.pm2p5[nowDate];
                let pm10ValueCurrent = COPDataLocal.pm10[nowDate];
                let no2ValueCurrent = COPDataLocal.no2[nowDate];
                let o3ValueCurrent = COPDataLocal.o3[nowDate];

                // TODO - RG - Pollen Index using Calculation Mixin
                return {
                    pm25IndexCurrent: {
                        index: pm25IndexCurrent,
                        value: parseInt(pm25ValueCurrent),
                        color: this.getThreshold(pm25IndexCurrent, "pm25", "index").colour,
                        class: this.getThreshold(pm25IndexCurrent, "pm25", "index").class,
                        text: this.getThreshold(pm25IndexCurrent, "pm25", "index").text,
                    },
                    pm10IndexCurrent: {
                        index: pm10IndexCurrent,
                        value: parseInt(pm10ValueCurrent),
                        color: this.getThreshold(pm10IndexCurrent, "pm10", "index").colour,
                        class: this.getThreshold(pm10IndexCurrent, "pm10", "index").class,
                        text: this.getThreshold(pm10IndexCurrent, "pm10", "index").text,
                    },
                    no2IndexCurrent: {
                        index: no2IndexCurrent,
                        value: parseInt(no2ValueCurrent),
                        color: this.getThreshold(no2IndexCurrent, "no2", "index").colour,
                        class: this.getThreshold(no2IndexCurrent, "no2", "index").class,
                        text: this.getThreshold(no2IndexCurrent, "no2", "index").text,
                    },
                    o3IndexCurrent: {
                        index: o3IndexCurrent,
                        value: parseInt(o3ValueCurrent),
                        color: this.getThreshold(o3IndexCurrent, "o3", "index").colour,
                        class: this.getThreshold(o3IndexCurrent, "o3", "index").class,
                        text: this.getThreshold(o3IndexCurrent, "o3", "index").text,
                    },
                };
            },
        },

    };
</script>

<style scoped>
    .loader{
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        z-index: 9999;
    }
</style>

