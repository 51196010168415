<template>

  <div class="pa-4 heading5 robotocondensed text-left white--text">

      <!--Page Title-->
      <div class="text-center text-h5 primary--text font-weight-light">
          {{ formTitle }}
      </div>

      <!--Description-->
      <div class="mt-4 body-2 text-center grey--text text--darken-2">
          <strong>If you have a problem with the TAPP then please contact us with the form below.</strong>
      </div>

      <v-window v-model="step" class="mt-4">

          <!--Information window-->
          <v-window-item :value="1">

              <!--Form-->
              <v-form ref="contactForm" v-model="valid" lazy-validation @submit.prevent="sendContactMessage()">

                  <!--Subject-->
                  <v-text-field
                      background-color="background"
                      class="rounded-xl"
                      flat
                      hide-details="auto"
                      label="Enter subject"
                      required
                      :rules="subjectRules"
                      solo  
                      v-model="emailForm.emailSubject">
                  </v-text-field>
              
                  <!--Message-->
                  <v-textarea
                      background-color="background"
                      class="rounded-xl mt-4"
                      flat
                      hide-details="auto"
                      label="Write Your message"
                      required
                      :rules="messageRules"
                      solo  
                      v-model="emailForm.emailBody">
                  </v-textarea>

              </v-form>

              <!--Decision Buttons-->
              
              <div class="mt-4 d-flex justify-space-between">

                  <v-spacer/>

                  <v-btn 
                      class="mr-4"
                      color="secondary" 
                      @click="MIX_go('/')"
                      dark>
                      back
                  </v-btn>

                  <v-btn
                      color="primary"
                      @click="sendContactMessage()"
                      dark
                      type="submit">
                      SUBMIT
                  </v-btn>

              </div>

          </v-window-item>

          <!--Redirect Window-->
          <v-window-item :value="2">
            
            <div class="body-2 text-center secondary--text ">
              If you have a problem with the TAPP then please contact us with the form below.
            </div>
             
            <div class="mt-4 d-flex justify-center">
              <v-btn dark color="primary" @click="goToHome()" type="submit">Home</v-btn>
            </div>

          </v-window-item>

      </v-window>
  </div>

</template>

<script>

  export default {

    name: "ContactUs",
    
    props:['predifineBody', 'predifineMessage'],
    data: () => ({

      step: 1,
      valid: true,

      emailForm:{
        emailTo:'',
        emailFrom:'',
        emailSubject:'',
        emailBody:'',
      },
     

      subjectRules: [(v) => !!v || "Subject is required"],
      messageRules: [(v) => !!v || "Message is required"],
    }),

    computed: {

      formTitle() {
        if (this.step === 1) {
          return "Support Request";
        } else if (this.step === 2) {
          return "Message Sent Successfully! ";
        } else {
          return "Support Request";
        }
      },

    },
    methods: {
      async setUp(){

        let userReadResult = await localStorage.getItem("Contact_Info");
        const newObj = JSON.parse(userReadResult);
        this.emailForm.emailBody = newObj.body;
        this.emailForm.emailSubject = newObj.subject;
        this.MIX_writeToLocalStorage('Contact_Info', JSON.stringify({})) 

      },
     async sendContactMessage() {
      
        if (this.$refs.contactForm.validate()) {

          let userReadResult = await localStorage.getItem("TAPP_currentUser");
          const newObj = JSON.parse(userReadResult)
          this.emailForm.emailFrom = newObj.userEmail;
          this.emailForm.emailTo = "marinos@vindico.net";

          await this.MIX_firestore_create_autoId(this.emailForm, 'emails')

           const url = `https://europe-west2-ta-tapp-app.cloudfunctions.net/sendEmail?email_from=${this.emailForm.emailFrom}&message=${this.emailForm.emailBody}&subjectForm=${this.emailForm.emailSubject}`;
          // console.log("url: " + url);
            const requestOptions = {
              method: "GET",
              headers: { "Content-Type": "application/json", "Access-Control-Allow-Origin": "*"},
            };
            fetch(url, requestOptions);
            this.step++;
            
          } else {
            console.log("fail")

        }
        
      },
      goToHome() {
        this.$router.push("/").catch((error) => {
          console.log(error.message);
        });
      },
      validEmail(email) {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
      },
      clear() {
        this.emailFrom = {};
       
        this.$router.push("/").catch((error) => {
          console.log(error.message);
        });
      },

      
    },

    async created(){
      this.setUp();
    }
  };
</script>
