<template>
   
   <v-fade-transition>

      <v-overlay :value="isPageLoading" :opacity="0.9" absolute color="white" class="text-center">

         <v-progress-circular :size="100" :width="10" color="primary" indeterminate></v-progress-circular>

         <div class="primary--text font-weight-light animate__animated animate__pulse animate__infinite">
            Refreshing Data<br /><strong>Current Air Quality</strong>
         </div>
      
      </v-overlay>
   
   </v-fade-transition>
</template> 

<script>
export default {

   name: "pageLoading",

   props: ['isPageLoading']

}
</script>