<template>
    <div class="home">
        <div class="text-center mt-5 text-h5 primary--text font-weight-light">About</div>
        <v-card class="mt-4 elevation-0 rounded-lg pa-0">
            <div class="pt-5 px-5 mb-4 d-flex align-center fullwidth">
                <div class="text-h7 font-weight-light grey--text text--darken-2">Think Air</div>
                <v-spacer />
            </div>
            <div class="px-5 grey--text body-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
        </v-card>        
    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: "AboutView",
};
</script>

<style></style>
