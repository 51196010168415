import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCHlVVIAZFbP0c8GrdjkSWwHWT8tvmB3ic",
  authDomain: "ta-tapp-app.firebaseapp.com",
  projectId: "ta-tapp-app",
  storageBucket: "ta-tapp-app.appspot.com",
  messagingSenderId: "426572175397",
  appId: "1:426572175397:web:1061796d7895c4e8e047cc",
  measurementId: "G-KF2EE73ZCD"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const fv = firebase.firestore.FieldValue;

export {
    db,
    fv,
};