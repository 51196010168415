// import moment from "moment";

const mixin = {
	data: () => ({
        API_URL: process.env.VUE_APP_API_URL,
        API_MET: process.env.VUE_APP_API_URL_MET,
	}),
	computed: {
	},
	methods: {
		// * CHANGE ROUTE FUNCTION
		MIX_go(path) {
			this.$router.push(path).catch((err) => {
				console.log("Route error: " + err);
			});
		},
		// * CHANGE ROUTE FUNCTION
		MIX_goExternal(path) {
			window.open(path, '_blank').focus();
		},
        MIX_generateId() {
            let generatedId = "";
            const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"; // Alphanumeric characters
            for (let i = 0; i < 20; i++) {
                generatedId += chars.charAt(Math.floor(Math.random() * chars.length));
            }
            return generatedId;
        },
        MIX_writeToLocalStorage(key, value) {
            localStorage.setItem(key, value)
        },
        MIX_readFromLocalStorage(key) {
            return localStorage.getItem(key)
        },
        MIX_deleteFromLocalStorage(key) {
            localStorage.removeItem(key);
        },
        MIX_getLocationData(dataSource, postcode) {        
            let t = this;
            let url = `${t.API_URL}/${dataSource}?postcode=${postcode}&key=${process.env.VUE_APP_API_KEY}`
            //console.log('url', url)
            return new Promise((resolve) => {
                t.$axios.get(url)
                    .then(response => {
                        resolve(response.data)
                    });
            });
        },
        MIX_getMetLocationData( postcode) {        
            let t = this;
            let url = `${t.API_URL}/met?postcode=${postcode}&key=${process.env.VUE_APP_API_KEY}`
            //console.log('url', url)
            return new Promise((resolve) => {
                t.$axios.get(url)
                    .then(response => {
                        resolve(response.data)
                    });
            });
        },
    },
};


export default {
	install(Vue) {
		Vue.mixin(mixin);
	},
};
