import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@mdi/font/css/materialdesignicons.min.css";

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: "mdiSvg" | "icons8" || "mdi", // || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: "#7568E3",
                secondary: "#A37FEF",
                third: "#D4D1F2",
                background: "#E3DFF1",
                accent: "#83D4DA",
                accent2: "#3B5067",
                success: "#66BB6A",
                warning: "#FFA726",
                error: "#EF5350",
                extreme: "#AB47BC",
                low: "#66BB6A",
                moderate: "#FFA726",
                high: "#EF5350",
                veryhigh: "#AB47BC"


            },
        },
    },
});
