<template>
    <div class="home">

        <!--Logo-->
        <div class="d-flex justify-center mb-5">
            <img src="@/assets/logo.png" alt="logo" class="logo" height="100" />
        </div>
        
        <div class="font-weight-black text-h5 accent2--text">Welcome to TAPP</div>
        <div class="font-weight-light text-h5 primary--text">Think Air Polution Predictor App</div>
        <v-divider class="mt-2 mb-2"></v-divider>
        <div class="mt-3 body-2 grey--text text--darken-2 text-left">
            <strong>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</strong><br />
            Ut ultricies velit in nisi tincidunt malesuada. Aliquam nec fermentum justo. Nulla mi purus, convallis et odio sed, egestas elementum libero. Nunc condimentum nisl in massa rutrum feugiat non vehicula odio.
        </div>
        <v-form>
            <v-row class="mt-5" dense>
                <v-col cols="12" xs="12" sm="12">
                    <v-text-field solo flat append-icon="icons8-name" background-color="background" label="Full Name" v-model="registerForm.userName" required class="rounded-xl" hide-details="auto"
                     :error="errors.userName" :error-messages="errors.userNameErrorMessage"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12">
                    <v-text-field solo flat append-icon="icons8-mail" background-color="background" 
                    label="Email" v-model="registerForm.userEmail" required class="rounded-xl" 
                    hide-details="auto"
                    :error="errors.userEmail" :error-messages="errors.userEmailErrorMessage"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12">
                    <v-text-field solo flat append-icon="icons8-phone" background-color="background" label="Mobile Number" v-model="registerForm.userTelephone" 
                    class="rounded-xl" hide-details="auto" :error="errors.userTelephone" :error-messages="errors.userTelephoneErrorMessage"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12">
                    <v-text-field solo flat append-icon="icons8-location" background-color="background" label="Postcode" v-model="registerForm.userPostcode" class="rounded-xl" hide-details="auto"
                     :error="errors.userPostcode" :error-messages="errors.userPostcodeErrorMessage"></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12">
                    <div class="d-flex align-center">
                        <v-checkbox class="accent2--text pa-0 ma-0 mt-4" v-model="registerForm.userAcceptedTerms" label="I agree to the terms and conditions" hide-details="auto" false-value="0" true-value="1"
                       ></v-checkbox>
                    </div>
                    <div class="d-flex align-center">
                        <v-checkbox class="accent2--text pa-0 ma-0 mb-4" v-model="registerForm.userMarketingSubscribed" label="I agree to the marketing communications" hide-details="auto" false-value="0" true-value="1"></v-checkbox>
                    </div>
                </v-col>

                <v-col cols="12" xs="12" sm="12">
                    <v-btn depressed block class="primary rounded-xl text-h7 font-weight-bold accent--text" large height="50" @click="validateForm()">Register</v-btn>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
    name: "HomeView",
    components: {},

    data: () => ({

        registerForm: {
            id: "",
            userName: "",
            userEmail: "",
            userTelephone: "",
            userPostcode: "",
            userAcceptedTerms: "0",
            userMarketingSubscribed: "0"
        },

        errors: {
            userName: false,
            userNameErrorMessage: '',
            userEmail: false,
            userEmailErrorMessage: '',
            userTelephone: false,
            userTelephoneErrorMessage: '',
            userPostcode: false,
            userPostcodeErrorMessage: '',
        }
    }),
    methods: {

        // * Validate Form
        validateForm(){
            const t = this
            const emailRegex = /.+@.+\..+/
            // const postcodeRegex = /^[a-zA-Z]{1,2}[0-9]{1,2}[a-zA-Z]?[0-9][a-zA-Z]{2}$/

            t.errors.userName = false
            t.errors.userNameErrorMessage = ''
            t.errors.userEmail = false
            t.errors.userEmailErrorMessage = ''
            t.errors.userTelephone = false
            t.errors.userTelephoneErrorMessage = ''
            t.errors.userPostcode = false
            t.errors.userPostcodeErrorMessage == ''

            // Name
            if (!t.registerForm.userName.trim()) {
                t.errors.userName = true
                t.errors.userNameErrorMessage = 'Name required '
            }
            // Must be between 2 and 60 characters
            else if (t.registerForm.userName.trim().length < 2 || t.registerForm.userName.trim().length > 60) {
                t.errors.userName = true
                t.errors.userNameErrorMessage = 'Name must be between 2 and 60 characters'
            }

            // Email
            if (!t.registerForm.userEmail.trim()) {
                t.errors.userEmail = true
                t.errors.userEmailErrorMessage = 'Email required'
            }
            // Must be a (simple) valid email address
            else if (!emailRegex.test(t.registerForm.userEmail)) {
                t.errors.userEmail = true
                t.errors.userEmailErrorMessage = 'Email not valid'
            }
            // User Contact Telephone
            if (!t.registerForm.userTelephone.trim()) {
                t.errors.userTelephone = true
                t.errors.userTelephoneErrorMessage = 'Telephone Number required'
            }
            // Landline number must start 01, 02 or 03 and be either 10 or 11 digits
            else if (['1', '2', '3'].includes(t.registerForm.userTelephone.trim()[1]) && (t.registerForm.userTelephone.trim().length < 10 || t.registerForm.userTelephone.trim().length > 11)) {
                t.errors.userTelephone = true
                t.errors.userTelephoneErrorMessage = 'Landline numbers must have either 10 or 11 digits'
            }
            // Mobile number must start 07 and be 11 digits
            else if (['7'].includes(t.registerForm.userTelephone.trim()[1]) && t.registerForm.userTelephone.trim().length !== 11) {
                t.errors.userTelephone = true
                t.errors.userTelephoneErrorMessage = 'Mobile numbers must have 11 digits'
            }
            // Number must start 01, 02, 03 or 07
            else if (!['0'].includes(t.registerForm.userTelephone[0]) || ['0', '4', '5', '6', '8', '9'].includes(t.registerForm.userTelephone[1])) {
                t.errors.userTelephone = true
                t.errors.userTelephoneErrorMessage = 'Landline numbers start 01, 02 or 03. Mobile numbers must start 07'
            }

            // Address Postcode
            if (!t.registerForm.userPostcode.trim()) {
                t.errors.userPostcode = true
                t.errors.userPostcodeErrorMessage = 'Postcode required'
            } 
            
            //Must be a valid UK POSTCODE
            // else if (!postcodeRegex.test(validPostcode)) {
            //     t.errors.userPostcode = true
            //     t.errors.userPostcodeErrorMessage = 'Postcode not valid'
            // }

            // Check if there any errors left
            if (!Object.values(t.errors).includes(true)) {
                t.register()
            }
        },

        async register() {
            // TODO - Store Registration details in Firebase
            this.registerForm.id = this.MIX_generateId();
            // check if user is already registered
            let userExistsResult = await this.MIX_firestore_readByEmail(this.registerForm.userEmail, 'users')
            if (userExistsResult.code === 1) {
                // user already registered update deatils
                this.registerForm.id = userExistsResult.data[0].id;
                let updateResult = await this.MIX_firestore_update(userExistsResult.data[0], 'users', this.registerForm)
                if (updateResult.code === 1) {
                    this.MIX_writeToLocalStorage("TAPP_currentUser", JSON.stringify(this.registerForm));
                    this.MIX_writeToLocalStorage('TAPP_currentPostcode', this.registerForm.userPostcode);
                    this.$emit('updateuser', this.registerForm)
                    this.MIX_go('/');
                } else {
                    // An Error Occured
                    console.log('Error Occured ', updateResult.error);
                }                
            } else if (userExistsResult.code === 0) {
                // user does not exist
                let createResult = await this.MIX_firestore_create(this.registerForm, 'users')
                if (createResult.code === 1) {
                    this.MIX_writeToLocalStorage("TAPP_currentUser", JSON.stringify(this.registerForm));
                    this.MIX_writeToLocalStorage('TAPP_currentPostcode', this.registerForm.userPostcode);
                    this.$emit('updateuser', this.registerForm)
                    this.MIX_go('/');
                } else {
                    // An Error Occured
                    console.log('Error Occured ', createResult.error);
                }
            } else {
                // An Error Occured
                console.log('Error Occured - Unknown');
            }
        },
    },
    async created() {
    }
};
</script>
<style>
.v-label {
    padding-left: 10px !important;
}
.v-input__icon--append {
    padding-right: 10px !important;
}
</style>
