import Vue from "vue";
import VueRouter from "vue-router";
import RegisterView from "@/views/RegisterView.vue";
// import HomeView from "@/views/HomeView.vue";
import IndexView from "@/views/IndexView.vue";
import ForecastView from "@/views/ForecastView.vue";
import ExposureView from "@/views/ExposureView.vue";
import ResourcesView from "@/views/ResourcesView.vue";
import ProfileView from "@/views/ProfileView.vue";
import AboutView from "@/views/AboutView.vue";

import ContactUs from "@/views/contact-us/contact-us.vue";


Vue.use(VueRouter);

const routes = [

    {
        path: "/contact-us",
        name:"contact-us",
        component: ContactUs
    },

    {
        path: "/",
        name: "index",
        component: IndexView,
    },
    {
        path: "/register",
        name: "register",
        component: RegisterView,
    },
    {
        path: "/forecast",
        name: "forecast",
        component: ForecastView,
    },
    {
        path: "/exposure",
        name: "exposure",
        component: ExposureView,
    },
    {
        path: "/resources",
        name: "resources",
        component: ResourcesView,
    },
    {
        path: "/profile",
        name: "profile",
        component: ProfileView,
    },
    {
        path: "/about",
        name: "about",
        component: AboutView,
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    // RETURN TO TOP ON ROUTE CHANGE
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
});

router.beforeEach(async (to, from, next) => {
    // console.log("path=" + to.fullPath);
    navigator.serviceWorker.register("/service-worker.js").then((reg) => {
        reg.update();
    });
    let userReadResult = await localStorage.getItem("TAPP_currentUser");
    if (to.path !== "/register" && userReadResult === null) {
        next("/register");
    } else {
        next();
    }
});

export default router;
