<template>

    <div class="forecast">


        <!--Title-->
        <div class="text-center text-h5 primary--text font-weight-light">
            Outdoor Exposure
        </div>

        <!-- PM 2.5 short Term Exposure -->
        <v-card class="mt-4 elevation-0 rounded-lg">

            <div class="pa-4 d-flex align-center fullwidth">
                
                <!--Header-->
                <div class="text-h6 font-weight-light grey--text text--darken-2">
                    PM2.5 Short Term exposure
                </div>

            </div>

            <!--Bar Chart-->
            <bar-chart class="mt-4" :seriesdata="findSeriesData('pm25')" :xaxisdata="computedXaxisData" />

            <!--Read More-->
            <v-expansion-panels color="primary" class="elevation-0 ma-0 pa-0" accordian flat tile>

                <v-expansion-panel class="white lighten-5">

                    <v-expansion-panel-header class="font-weight-bold primary--text"> 
                        Read More 
                    </v-expansion-panel-header>

                    <v-expansion-panel-content class="grey--text text--darken-2" style="margin: 0px !important; padding: 0px !important"> 
                        
                        <div class="mt-4">
                            The short-term exposure charts allow users to monitor their potential exposure over the last 30 days for each pollutant.
                        </div>

                    </v-expansion-panel-content>

                </v-expansion-panel>

            </v-expansion-panels>
        </v-card>

        <!-- PM 2.5 Long Term Exposure -->
        <v-card class="mt-4 elevation-0 rounded-lg">

            <div class="pa-4 d-flex align-center fullwidth">
                
                <!--Header-->
                <div class="text-h6 font-weight-light grey--text text--darken-2">
                    PM2.5 Long Term Exposure
                </div>

            </div>

             <!--Bar Chart-->
            <bar-chart class="mt-4" :seriesdata="findAverageSeriesData('pm25')"/>
            <!--Read More-->
            <v-expansion-panels color="primary" class="elevation-0 ma-0 pa-0" accordian flat tile>

                <v-expansion-panel class="white lighten-5">

                    <v-expansion-panel-header class="font-weight-bold primary--text"> 
                        Read More 
                    </v-expansion-panel-header>

                    <v-expansion-panel-content class="grey--text text--darken-2" style="margin: 0px !important; padding: 0px !important"> 
                        
                        <div class="mt-4">
                            The long-term exposure charts show a rolling average of outdoor exposure up to a period of a year for each pollutant.
                        </div>

                    </v-expansion-panel-content>

                </v-expansion-panel>

            </v-expansion-panels>
        </v-card>

        <!-- PM 10 short Term Exposure -->
        <v-card class="mt-4 elevation-0 rounded-lg">

            <div class="pa-4 d-flex align-center fullwidth">
                
                <!--Header-->
                <div class="text-h6 font-weight-light grey--text text--darken-2">
                    PM10 Short Term Exposure
                </div>

            </div>
        
            <!--Bar Chart-->
            <bar-chart class="mt-4" :seriesdata="findSeriesData('pm10')" :xaxisdata="computedXaxisData" />
             <!--Read More-->
            <v-expansion-panels color="primary" class="elevation-0 ma-0 pa-0" accordian flat tile>

                <v-expansion-panel class="white lighten-5">

                    <v-expansion-panel-header class="font-weight-bold primary--text"> 
                        Read More 
                    </v-expansion-panel-header>

                    <v-expansion-panel-content class="grey--text text--darken-2" style="margin: 0px !important; padding: 0px !important"> 
                        
                        <div class="mt-4">
                            The short-term exposure charts allow users to monitor their potential exposure over the last 30 days for each pollutant.
                        </div>

                    </v-expansion-panel-content>

                </v-expansion-panel>

            </v-expansion-panels>
        </v-card>

        <!-- PM 10 Long Term Exposure -->
        <v-card class="mt-4 elevation-0 rounded-lg">

            <div class="pa-4 d-flex align-center fullwidth">
                
                <!--Header-->
                <div class="text-h6 font-weight-light grey--text text--darken-2">
                    PM10 Long Term Exposure
                </div>

            </div>

            <!--Bar Chart-->
            <bar-chart class="mt-4" :seriesdata="findAverageSeriesData('pm10')"/>

             <!--Read More-->
            <v-expansion-panels color="primary" class="elevation-0 ma-0 pa-0" accordian flat tile>

                <v-expansion-panel class="white lighten-5">

                    <v-expansion-panel-header class="font-weight-bold primary--text"> 
                        Read More 
                    </v-expansion-panel-header>

                    <v-expansion-panel-content class="grey--text text--darken-2" style="margin: 0px !important; padding: 0px !important"> 
                        
                        <div class="mt-4">
                            The long-term exposure charts show a rolling average of outdoor exposure up to a period of a year for each pollutant.
                        </div>

                    </v-expansion-panel-content>

                </v-expansion-panel>

            </v-expansion-panels>
        </v-card>

        <!-- Ozone short Term Exposure -->
        <v-card class="mt-4 elevation-0 rounded-lg">

            <div class="pa-4 d-flex align-center fullwidth">
                
                <!--Header-->
                <div class="text-h6 font-weight-light grey--text text--darken-2">
                    Ozone Short Term Exposure
                </div>

            </div>

             <!--Bar Chart-->
            <bar-chart class="mt-4" :seriesdata="findSeriesData('o3')" :xaxisdata="computedXaxisData" />
             <!--Read More-->
            <v-expansion-panels color="primary" class="elevation-0 ma-0 pa-0" accordian flat tile>

                <v-expansion-panel class="white lighten-5">

                    <v-expansion-panel-header class="font-weight-bold primary--text"> 
                        Read More 
                    </v-expansion-panel-header>

                    <v-expansion-panel-content class="grey--text text--darken-2" style="margin: 0px !important; padding: 0px !important"> 
                        
                        <div class="mt-4">
                            The short-term exposure charts allow users to monitor their potential exposure over the last 30 days for each pollutant.
                        </div>

                    </v-expansion-panel-content>

                </v-expansion-panel>

            </v-expansion-panels>
        </v-card>

        <!-- Ozone Long Term Exposure -->
        <v-card class="mt-4 elevation-0 rounded-lg">

            <div class="pa-4 d-flex align-center fullwidth">
                
                <!--Header-->
                <div class="text-h6 font-weight-light grey--text text--darken-2">
                    Ozone Long Term Exposure
                </div>

            </div>

             <!--Bar Chart-->
            <bar-chart class="mt-4" :seriesdata="findAverageSeriesData('o3')"/>

             <!--Read More-->
            <v-expansion-panels color="primary" class="elevation-0 ma-0 pa-0" accordian flat tile>

                <v-expansion-panel class="white lighten-5">

                    <v-expansion-panel-header class="font-weight-bold primary--text"> 
                        Read More 
                    </v-expansion-panel-header>

                    <v-expansion-panel-content class="grey--text text--darken-2" style="margin: 0px !important; padding: 0px !important"> 
                        
                        <div class="mt-4">
                            The long-term exposure charts show a rolling average of outdoor exposure up to a period of a year for each pollutant.
                        </div>

                    </v-expansion-panel-content>

                </v-expansion-panel>

            </v-expansion-panels>
        </v-card>

    </div>
</template>

<script>

    // import BarChart from "@/components/BarChart.vue";


    export default {

        name: "ExposureView",

        components: {

            // BarChart

        },

        data: () => ({

            forecastDataPM25Index: {},
            forecastDataPM25: {},
            forecastDataPM10: {},
            forecastDataO3: {},

        }),
        computed: {

             computedXaxisData() {
                
                let xAxisData = [];

                for ( var i=0; i < this.findCurrentMonth(); i++){
                    xAxisData.push(
                        this.$moment().startOf("month").add( i , "day").format("DD MMM")
                    );
                }
               
                return xAxisData;
            },
        },
        // * METHODS
        methods: {

            getThreshold(value, type, thresholdtype) {
                let threshold = this.thresholds[type].find((x) => {
                    if (thresholdtype === "minmax") {
                        if (value >= x.min && value < x.max) {
                            return x;
                        }
                    } else if (thresholdtype === "index") {
                        if (value === x.index) {
                            return x;
                        }
                    }
                });
                return threshold;
            },

            findAverageSeriesData (type){

                let index = [];
                let COPDataLocalResult = this.MIX_readFromLocalStorage("TAPP_COPData");
                let COPDataLocal = COPDataLocalResult ? JSON.parse(COPDataLocalResult) : null;
                
                let totalValue = 0;
                let count =0;

                for (let i = 0; i <= 365 ; i++) {
                    let nowDate = this.$moment()
                        .startOf("day")
                        .subtract( i, "days")
                        .format("X");

                      
                    let dataValue = "";
                    if(type == "pm25"){
                        dataValue = COPDataLocal.pm2p5[nowDate]
                    }else if ( type == "pm10") {
                        dataValue = COPDataLocal.pm10[nowDate]
                    }else if (type =="o3"){
                        dataValue = COPDataLocal.o3[nowDate]
                    }
                    
                    if( dataValue ){
                        totalValue = parseFloat(dataValue).toFixed(2);
                        count ++;
                     
                    }
                    
                }
                let average = totalValue/count;
                index.push({
                    value: average,
                
                    itemStyle: {
                        color: this.getThreshold(average, type, "minmax").colour,
                    },
                
                });
                return index;
            },
           
             findSeriesData( type ) {
                let index = [];
                let COPDataLocalResult = this.MIX_readFromLocalStorage("TAPP_COPData");
                let COPDataLocal = COPDataLocalResult ? JSON.parse(COPDataLocalResult) : null;
                
               
                for (let i = 0; i < this.findCurrentMonth() ; i++) {
                    let nowDate = this.$moment()
                        .startOf("month")
                        .add( i, "days")
                        .format("X");

                      
                    let dataValue = "";
                    if(type == "pm25"){
                        dataValue = COPDataLocal.pm2p5[nowDate]
                    }else if ( type == "pm10") {
                        dataValue = COPDataLocal.pm10[nowDate]
                    }else if (type =="o3"){
                        dataValue = COPDataLocal.o3[nowDate]
                    }
                    
                    
                    if( dataValue ){
                        index.push({
                            value: dataValue,
                        
                            itemStyle: {
                                color: this.getThreshold(dataValue, type, "minmax").colour,
                            },
                        
                        });

                    }else{

                        index.push({
                            value: 0,
                        
                            itemStyle: {
                                color: this.getThreshold(0, type, "minmax").colour,
                            },
                        });
                   
                    }
                }
                return index;
            },
            
            findCurrentMonth(){
                let currentMonth = this.$moment().month()
                let dayOfCurrMonth = this.$moment( currentMonth ).daysInMonth()

                return dayOfCurrMonth;
            }
        },
        async created() {
        
        },
    };
</script>
