<template>
    <div class="forecast">
        <div class="text-center text-h5 primary--text font-weight-light">Air Quality Forecast</div>

        <!-- PM 2.5 Forecast -->
        <v-card class="mt-4 elevation-0 rounded-lg pa-0">
            <div class="pt-5 px-5 mb-4 d-flex align-center fullwidth">
                <div class="text-h6 font-weight-light grey--text text--darken-2">AQI Forecast <span class="body-2 font-weight-bold primary--text">TAQI</span></div>
                <v-spacer />
            </div>

            <forecast-met :forecastdata="forecastDataPM25Index" :forecastmetdata="forecastDataMETIndex" :forecastDates="forecastDates"
                datatype="pm25" thresholdtype="index"/>
            <v-expansion-panels color="primary" class="elevation-0 ma-0 pa-0" accordian flat tile>
                <v-expansion-panel class="white lighten-5">
                    <v-expansion-panel-header class="font-weight-bold primary--text"> Read More </v-expansion-panel-header>
                    <v-expansion-panel-content class="grey--text text--darken-2" style="margin: 0px !important; padding: 0px !important">
                        AQI Thresholds<br />
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">Min</th>
                                        <th class="text-left">Max</th>
                                        <th class="text-left">Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in thresholds.pm25" :key="item.min">
                                        <td>{{ item.min }}</td>
                                        <td>{{ item.max }}</td>
                                        <td :class="item.class">{{ item.text }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <div class="mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card>

        <!-- Hospital Admission Index-->
        <v-card class="mt-4 elevation-0 rounded-lg pa-0">

                <div class="pa-4 text-h6 font-weight-light grey--text text--darken-2">
                    Hospital Admission index
                </div>

            <hospital-admission 
            
            :forecastdataPM25="forecastDataPM25"
            :forecastdataO3="forecastDataO3"
            :forecastDates="forecastDates"/>

            <v-expansion-panels color="primary" class="elevation-0 ma-0 pa-0" accordian flat tile>
                <v-expansion-panel class="white lighten-5">
                    <v-expansion-panel-header class="font-weight-bold primary--text"> Read More </v-expansion-panel-header>
                    <v-expansion-panel-content class="grey--text text--darken-2" style="margin: 0px !important; padding: 0px !important">
                        <div class="mt-4">
                            The Hospital Admission Index shows the total and individual predicted increase in percentage change in hospital admissions for COPD 
                            given the forecasted pollution levels. 
                            They should be used as a guide to help you plan how you minimise your exposure to outdoor air pollution over the coming days
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card>

        <!-- PM2.5 Map -->
        <v-card class="mt-4 elevation-0 rounded-lg pa-0">
            <div class="pt-5 px-5 mb-1 d-flex align-center fullwidth">
                <div class="text-h6 font-weight-light grey--text text--darken-2">AQI MAP <span class="body-2 font-weight-bold primary--text">TAQI</span></div>
                <v-spacer />
            </div>
            <div class="mb-4"><v-img v-if="decodedImage !== ''" :src="`data:image/png;base64, ${decodedImage}`" height="100%" width="100%" cover /></div>

             <!--Read More-->
            <v-expansion-panels color="primary" class="elevation-0 ma-0 pa-0" accordian flat tile>

                <v-expansion-panel class="white lighten-5">

                    <v-expansion-panel-header class="font-weight-bold primary--text"> 
                        Read More 
                    </v-expansion-panel-header>

                    <v-expansion-panel-content class="grey--text text--darken-2" style="margin: 0px !important; padding: 0px !important"> 
                        
                        <div class="mt-4">
                            The heat map shows air quality across the UK for the next 24 hours coloured using the TAQI levels.
                        </div>

                    </v-expansion-panel-content>

                </v-expansion-panel>

            </v-expansion-panels>
            
        </v-card>

        <!-- PM 2.5 Forecast -->
        <v-card class="mt-4 elevation-0 rounded-lg pa-0">
            <div class="pt-5 px-5 mb-4 d-flex align-center fullwidth">
                <div class="text-h6 font-weight-light grey--text text--darken-2">PM2.5 Forecast <span class="body-2 font-weight-bold primary--text">µg/m3</span></div>
                <v-spacer />
            </div>
            <forecast-table :forecastdata="forecastDataPM25" :forecastDates="forecastDates" datatype="pm25" thresholdtype="minmax" />
            <v-expansion-panels color="primary" class="elevation-0 ma-0 pa-0" accordian flat tile>
                <v-expansion-panel class="white lighten-5">
                    <v-expansion-panel-header class="font-weight-bold primary--text"> Read More </v-expansion-panel-header>
                    <v-expansion-panel-content class="grey--text text--darken-2" style="margin: 0px !important; padding: 0px !important">
                        PM 2.5 Thresholds<br />
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">Min</th>
                                        <th class="text-left">Max</th>
                                        <th class="text-left">Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in thresholds.pm25" :key="item.min">
                                        <td>{{ item.min }}</td>
                                        <td>{{ item.max }}</td>
                                        <td :class="item.class">{{ item.text }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <div class="mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card>

        <!-- PM 10 Forecast -->
        <v-card class="mt-4 elevation-0 rounded-lg pa-0">
            <div class="pt-5 px-5 mb-4 d-flex align-center fullwidth">
                <div class="text-h6 font-weight-light grey--text text--darken-2">PM 10 Forecast <span class="body-2 font-weight-bold primary--text">µg/m3</span></div>
                <v-spacer />
            </div>
            <forecast-table :forecastdata="forecastDataPM10" :forecastDates="forecastDates" datatype="pm10" thresholdtype="minmax" />
            <v-expansion-panels color="primary" class="elevation-0 ma-0 pa-0" accordian flat tile>
                <v-expansion-panel class="white lighten-5">
                    <v-expansion-panel-header class="font-weight-bold primary--text"> Read More </v-expansion-panel-header>
                    <v-expansion-panel-content class="grey--text text--darken-2" style="margin: 0px !important; padding: 0px !important">
                        PM 10 Thresholds<br />
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">Min</th>
                                        <th class="text-left">Max</th>
                                        <th class="text-left">Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in thresholds.pm25" :key="item.min">
                                        <td>{{ item.min }}</td>
                                        <td>{{ item.max }}</td>
                                        <td :class="item.class">{{ item.text }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <div class="mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card>

        <!-- OZONE O3 Forecast -->
        <v-card class="mt-4 elevation-0 rounded-lg pa-0">
            <div class="pt-5 px-5 mb-4 d-flex align-center fullwidth">
                <div class="text-h6 font-weight-light grey--text text--darken-2">Ozone Forecast <span class="body-2 font-weight-bold primary--text">µg/m3</span></div>
                <v-spacer />
            </div>
            <forecast-table :forecastdata="forecastDataO3" :forecastDates="forecastDates" datatype="o3" thresholdtype="minmax" />
            <v-expansion-panels color="primary" class="elevation-0 ma-0 pa-0" accordian flat tile>
                <v-expansion-panel class="white lighten-5">
                    <v-expansion-panel-header class="font-weight-bold primary--text"> Read More </v-expansion-panel-header>
                    <v-expansion-panel-content class="grey--text text--darken-2" style="margin: 0px !important; padding: 0px !important">
                        Ozone Thresholds<br />
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">Min</th>
                                        <th class="text-left">Max</th>
                                        <th class="text-left">Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in thresholds.pm25" :key="item.min">
                                        <td>{{ item.min }}</td>
                                        <td>{{ item.max }}</td>
                                        <td :class="item.class">{{ item.text }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <div class="mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card>

    </div>
</template>

<script>
// import {Buffer} from 'buffer';
export default {
    name: "ForecastView",
    data: () => ({
        loading: false,
        forecastDataPM25Index: {},
        forecastDataPM25: {},
        forecastDataPM10: {},
        forecastDataO3: {},
        forecastDataMETIndex:{},
        decodedImage: "",
    }),
    computed: {
        forecastDates() {
            return {
                today: {
                    day: this.$moment().format("ddd"),
                    date: this.$moment().format("YYYY-MM-DD"),
                    startTimeHuman: this.$moment().startOf("day").add(1, "hour").format("HH:mm:ss"),
                    startTimeUnix: this.$moment().startOf("day").add(1, "hour").format("X"),
                    endTimeHuman: this.$moment().endOf("day").add(1, "second").format("HH:mm:ss"),
                    endTimeUnix: this.$moment().endOf("day").add(1, "second").format("X"),
                },
                tomorrow: {
                    day: this.$moment().add(1, "day").format("ddd"),
                    date: this.$moment().add(1, "day").format("YYYY-MM-DD"),
                    startTimeHuman: this.$moment().add(1, "day").startOf("day").add(1, "hour").format("HH:mm:ss"),
                    startTimeUnix: this.$moment().add(1, "day").startOf("day").add(1, "hour").format("X"),
                    endTimeHuman: this.$moment().add(1, "day").endOf("day").add(1, "second").format("HH:mm:ss"),
                    endTimeUnix: this.$moment().add(1, "day").endOf("day").add(1, "second").format("X"),
                },
                dayAfterTomorrow: {
                    day: this.$moment().add(2, "day").format("ddd"),
                    date: this.$moment().add(2, "day").format("YYYY-MM-DD"),
                    startTimeHuman: this.$moment().add(2, "day").startOf("day").add(1, "hour").format("HH:mm:ss"),
                    startTimeUnix: this.$moment().add(2, "day").startOf("day").add(1, "hour").format("X"),
                    endTimeHuman: this.$moment().add(2, "day").endOf("day").add(1, "second").format("HH:mm:ss"),
                    endTimeUnix: this.$moment().add(2, "day").endOf("day").add(1, "second").format("X"),
                },
            };
        },
    },
    // * METHODS
    methods: {
        getThreshold(value, type, thresholdtype) {
            let threshold = this.thresholds[type].find((x) => {
                if (thresholdtype === "minmax") {
                    if (value >= x.min && value < x.max) {
                        return x;
                    }
                } else if (thresholdtype === "index") {
                    if (value === x.index) {
                        return x;
                    }
                }
            });
            return threshold;
        },
        airQualityForecast() {
            let COPDataLocalResult = this.MIX_readFromLocalStorage("TAPP_COPData");
            let COPDataLocal = COPDataLocalResult ? JSON.parse(COPDataLocalResult) : null;
            // console.log("COPDataLocal", COPDataLocal);
            this.forecastDataPM25Index = COPDataLocal.pm2p5_taqi;
            this.forecastDataPM25 = COPDataLocal.pm2p5;
            this.forecastDataPM10 = COPDataLocal.pm10;
            this.forecastDataO3 = COPDataLocal.o3;

            let METDataLocalResult = this.MIX_readFromLocalStorage("TAPP_METData");
            let METDataLocal = METDataLocalResult ? JSON.parse(METDataLocalResult) : null;
            // console.log("COPDataLocal", COPDataLocal);
            this.forecastDataMETIndex = METDataLocal;
        },
        async decodeImage() {
            const t = this;
            let image = await t.getMapImage();
            image = image.content;
            t.decodedImage = image;
        },
        async getMapImage() {
            this.mapImage = "";
            let url = "https://tapp-api.thinkair.co.uk/map?key=YPzdbIwpVIJKdG_ZD_c7Zw&time=" + this.$moment().format("X");
            // console.log("url=" + url);
            return await this.$axios({
                method: "get",
                url: url,
                headers: {
                    "Content-type": "image/jpg",
                },
            })

                .then((response) => {
                    // console.log("response", response.data);
                    return response.data;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    async created() {
        // console.log(this.$moment().format("X"));
        this.loading = true;
        this.airQualityForecast();
        this.decodeImage();
        this.loading = false;
    },
};
</script>
