<template>
    
    <div class="pa-4">

        <!--Day Tabs-->
        <v-tabs v-model="selectedDay" fixed-tabs>

            <v-tab :href="'#today'">{{ forecastDates.today.day }}</v-tab>
            <v-tab :href="'#tomorrow'">{{ forecastDates.tomorrow.day }}</v-tab>
            <v-tab :href="'#dayAfterTomorrow'">{{ forecastDates.dayAfterTomorrow.day}}</v-tab>

        </v-tabs>

        <!--Tablle-->
        <v-simple-table class="mt-4">
            
            <template v-slot:default>

                <tbody>
                    <tr> 
                        <td> Total </td>
                        <td> {{totalRisk()}}% </td>
                    </tr>
                    <tr> 
                        <td>  PM2.5 </td>
                        <td> {{calculatePM25()}}% </td>
                    </tr>
                    <tr>
                        <td>  O3 </td>
                        <td> {{calculateO3()}}% </td>
                    </tr>
                   
                </tbody>

            </template>

        </v-simple-table> 
       
    </div>
    
</template>

<script>
export default {
    name: "HospitalAdmissionIndex",
    props: {
        forecastdataPM25: {
            type: Object,
            default: () => {
                return {};
            },
        },
        forecastdataO3: {
            type: Object,
            default: () => {
                return {};
            },
        },
        forecastDates: {
            type: Object,
            default: () => {
                return {};
            },
        },
       
    
    },
    data: () => ({
        selectedDay: "today",
        riskEstiMateO3 : "",
        riskEstiMatePM25: "",
    }),
    methods: {
        totalRisk(){
           return  this.riskEstiMateO3 + this.riskEstiMatePM25
        },
        calculatePM25(){
            let totalValue = 0;
            for (let i = 1; i <= 24 ; i++) {
                    
                let time = this.$moment(this.forecastDates[this.selectedDay].startTimeUnix, "X").add( i, "hours").format("X");
                if(this.forecastdataPM25[time]){
                    totalValue += parseInt(this.forecastdataPM25[time]);
                }
               
            }
            totalValue = totalValue * 0.236
            this.riskEstiMatePM25 = parseInt(totalValue);
            return parseInt(totalValue)
        },      
        
        calculateO3(){
            
            
            //If the selected day is TODAY then we do not have enought data to calculate the mean for 8hour before 8 PM 
            //so we start calculate the mean of every hour after 8 PM
            if ( this.selectedDay === "today"){
                this.riskEstiMateO3= this.calculateO3Today()
            }
            else if (this.selectedDay === "tomorrow"){
                this.riskEstiMateO3 = this.calculateO32Days( "today")
            }else if (this.selectedDay === "dayAfterTomorrow"){
                this.riskEstiMateO3 = this.calculateO32Days("tomorrow")
            }
            
            return this.riskEstiMateO3
        },

        calculateO3Today(){
            // let totalCount = 0;
            let meanPerHour = [];

            for (var hour = 9;  hour <= 24 ; hour++) {
                
                let totalPer8hour =  0; 
                for (var hour8 = hour; hour8 >=hour-8; hour8--){
                    let time = this.$moment(this.forecastDates[this.selectedDay].startTimeUnix, "X").add( hour8, "hours").format("X");
                    totalPer8hour += parseInt(this.forecastdataO3[time]);

                }

                meanPerHour.push( parseInt(totalPer8hour /8) )

            }

            let meanOfDay = Math.max.apply( null, meanPerHour)
            meanOfDay = meanOfDay * 0.045
            
            return parseInt( meanOfDay)
        },

        calculateO32Days( dayBefore) {

            // let totalCount = 0;
            let meanPerHour = [];

            for (var hour = 0;  hour < 24 ; hour++) {

                let totalPer8hour =  0; 

                if( hour < 8){
                    for ( var hourDayBefore = 0; hourDayBefore <= 8; hourDayBefore++){
                        let time = this.$moment(this.forecastDates[dayBefore].endTimeUnix, "X").subtract( hourDayBefore, "hours").format("X");
                        totalPer8hour += parseInt(this.forecastdataO3[time]);
                    }
    
                }else{
                    for (var hour8 = hour; hour8 >=hour-8; hour8--){
                    let time = this.$moment(this.forecastDates[this.selectedDay].startTimeUnix, "X").add( hour8, "hours").format("X");
                    totalPer8hour += parseInt(this.forecastdataO3[time]);

                }
                }
                

                meanPerHour.push( parseInt(totalPer8hour /8) )

            }

            let meanOfDay = Math.max.apply( null, meanPerHour)
            meanOfDay = meanOfDay * 0.045

            return parseInt( meanOfDay)
        },
        


    },
    created (){
      
    }
};
</script>




