import Vue from 'vue';
import App from '@/App.vue';
import vuetify from '@/vuetify.js';
import router from '@/router.js';

// * GLOBAL MIXIN
import mixin from "@/mixin.js";
Vue.use(mixin);

import taqicalcMixin from "@/taqicalc.js";
Vue.use(taqicalcMixin);

// Libraries Required
import moment from 'moment';
import axios from 'axios';
Vue.prototype.$moment = moment;
Vue.prototype.$axios = axios;

Vue.config.productionTip = false;

// Roboto Fonts
import '../node_modules/typeface-roboto/index.css'

// Animate.css
import "animate.css";

// Icons8 CSS Import
import "@/assets/css/styles.min.css";

// Global Components
import GuageChart from '@/components/GuageChart.vue';
Vue.component('GuageChart', GuageChart);

import ForecastTable from '@/components/ForecastTable.vue';
Vue.component('ForecastTable', ForecastTable);

import BarChart from '@/components/BarChart.vue';
Vue.component('BarChart', BarChart);

import ForecastMet from '@/components/ForecastMetTable.vue';
Vue.component('ForecastMet', ForecastMet);

import CheckNetwork from '@/components/connection/CheckNetwork.vue';
Vue.component('CheckNetwork', CheckNetwork);

import HospitalAdmission from '@/components/HospitalAdmission.vue'
Vue.component('HospitalAdmission', HospitalAdmission);


// appupdate Component - using service worker
require('@/components/appupdate/appupdate_import.js');

// Firebase
//* FIREBASE INTEGRATION
const firebase = require("@/firebase/firebase-config.js"); // Import Firebase Config for Firebase Object Access
Vue.prototype.$firebase = firebase; // Prototype Firebase for use throughout Project $firebase
import firebase_firestore_mixin from "@/firebase/firebase_firestore_mixin.js"; //
Vue.use(firebase_firestore_mixin);

// Meta Tags for Page titles
import VueMeta from 'vue-meta'
Vue.use(VueMeta)

// Service Worker for PWA Installation
import './registerServiceWorker'

// Page Loader
import pageLoader from '@/components/commonComponents/pageLoading.vue';
Vue.component('pageLoader', pageLoader);

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
