import Vue from 'vue';

// * SERVICE WORKER INTEGRATION
import '@/registerServiceWorker';
import appupdate_mixin from '@/components/appupdate/appupdate_mixin.js';
Vue.use(appupdate_mixin);

// * APP UPDATE COMPONENT
import AppUpdate from '@/components/appupdate/AppUpdate.vue';
Vue.component('AppUpdate', AppUpdate);
