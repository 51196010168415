<template>
    <div>
        <v-chart class="chart" :option="option" />
    </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import { GridComponent } from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { ref, defineComponent } from "vue";

use([GridComponent, CanvasRenderer, BarChart,]);

export default defineComponent({
    name: "BarChart",
    components: {
        VChart,
    },
    props: {
        seriesdata: {
            type: Array,
            default: () => [],
        },
        xaxisdata: {
            type: Array,
            default: () => [],
        },
    },
    provide: {
        [THEME_KEY]: "light",
    },
    setup(vm) {
        const option = ref({
            xAxis: {
                type: "category",
                data: vm.xaxisdata
                // ["Now", "3PM", "4PM", "5PM", "6PM", "7PM"],
            },
            yAxis: {
                type: "value",
            },
            series: [
                {
                    data: vm.seriesdata,
                    // [
                    //     { value: 4, itemStyle: {color: '#FFA726'} },
                    //     { value: 7, itemStyle: {color: '#EF5350'} },
                    //     { value: 4, itemStyle: {color: '#EF5350'} },
                    //     { value: 3, itemStyle: {color: '#66BB6A'} },
                    //     { value: 2, itemStyle: {color: '#66BB6A'} },
                    //     { value: 2, itemStyle: {color: '#66BB6A'} }
                    // ],
                    type: "bar",
                },
            ],
        });

        return { option };
    },
});
</script>

<style scoped>
.chart {
    width: 100% !important;
    height: 300px !important;
    margin-bottom: -40px;
    margin-top: -50px;
    /* height: 100vh; */
}
</style>
