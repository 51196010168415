<template>
    <div v-if="user !== null">
        <v-app-bar app color="primary" class="" flat style="z-index: 99 !important; height: 64px !important">
            <div class="d-flex align-center" style="width: 100vw !important">
                <img src="@/assets/logo_white.png" alt="logo" class="logo" height="30" />
                <div class="ml-3 white--text font-weight-light text-h5">TAPP</div>
                <v-spacer />
                <v-icon large class="white--text mr-5" @click="refreshData">icons8-available-updates</v-icon>
                <v-menu elevation-0 :rounded="true" offset-y origin="top right" transition="scale-transition">
                    <template v-slot:activator="{ attrs, on }">
                        <v-btn icon class="white--text" v-bind="attrs" v-on="on">
                            <v-icon large class="white--text">icons8-male-user</v-icon>
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item @click="MIX_go('/about')">
                            <v-list-item-title>About Us</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="MIX_go('/profile')">
                            <v-list-item-title>My Profile</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="reloadApp()">
                            <v-list-item-title>Reload App</v-list-item-title>
                        </v-list-item>                        
                        <v-list-item @click="clearData()">
                            <v-list-item-title>Clear All Data</v-list-item-title>
                        </v-list-item> 
                         <v-list-item @click="MIX_go('/contact-us')">
                            <v-list-item-title>Contact Us</v-list-item-title>
                        </v-list-item>                    
                        <v-list-item disabled> Version {{ appVersion }} </v-list-item>
                    </v-list>
                </v-menu>
            </div>
        </v-app-bar>
        <v-banner app class="fullwidth third" style="height: 50px !important; position: fixed !important; z-index: 99 !important; border-top: 1px solid #3b5067 !important; border-bottom-left-radius: 15px !important; border-bottom-right-radius: 15px !important">
            <div class="d-flex align-start">
                <div class="accent2--text font-weight-black">{{ $moment().format("dddd DD MMM YYYY") }}</div>
                <v-spacer />
                <v-btn class="mt-2 rounded-xl primary" @click="changeLocation()" depressed>
                    <v-icon class="accent--text">icons8-location</v-icon>
                    <div class="ml-3 white--text font-weight-normal">{{ location }}</div></v-btn
                >
            </div>
        </v-banner>
        <div class="headerbanner"></div>
    </div>
</template>

<script>
    import { version } from "../../package.json";
export default {
    name: "app-footer",
    props: {
        user: {
            type: Object,
            default: null,
        },
        location: {
            type: String,
            default: "Unknown",
        },
    },
    data: () => ({
        appVersion: version,
        btns: [
            ["Removed", "0"],
            ["Large", "lg"],
            ["Custom", "b-xl"],
        ],
        colors: ["deep-purple accent-4", "error", "teal darken-1"],
        items: [...Array(4)].map((_, i) => `Item ${i}`),
    }),
    methods: {
        changeLocation() {
            this.$emit("changelocation");
        },
        reloadApp() {
            window.location.reload();
        },
        refreshData() {
            this.$emit("refreshdata");
        },
        clearData() {
            this.$emit("cleardata");
        },
    },
};
</script>

<style scoped>
.headerbanner {
    z-index: 9 !important;
    top: 100px;
    width: 100vw !important;
    position: fixed !important;
    margin-top: 0px !important;
    height: 35px !important;
    background: -moz-linear-gradient(bottom, rgb(243, 243, 244) 30%, rgba(243, 243, 244, 0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(bottom, rgb(243, 243, 244) 30%, rgba(243, 243, 244, 0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgb(243, 243, 244) 30%, rgba(243, 243, 244, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    /* background-color: red !important; */
}


</style>
